import React, { Component, forwardRef } from "react";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../config";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import numeral from "numeral";
import MaterialTable from "material-table";
import { TableBody, TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import FirstPage from "@material-ui/icons/FirstPage";
import toast, { Toaster } from "react-hot-toast";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import { Auth } from "aws-amplify";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Modal } from "react-bootstrap";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SaveAlt from "@material-ui/icons/SaveAlt";
import LoadingOverlay from "react-loading-overlay";
import Popup from "../../component/common/Popup";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import TextField from "../common/TextField.jsx";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

import DealClosedLostButtons from "../subcompo/DealClosedLostButtons";
import ConfirmDealButton from "../subcompo/ConfirmDealButton";

import { createCommentForDownload } from "../common/dashboard_helper.js";

import "./DashboardAdmin.scss";
import {
  getDashboardTableData,
  getSalesDashboardDataAdmin,
} from "../../api/commonApi.js";
import { getHeader } from "../../utils/common.js";
import { event } from "jquery";
import { th } from "react-dom-factories";

let ismobile = window.innerWidth < 1600;

const {
  DownloadDashboardFiles,
} = require("../subcompo/DownloadDashboardFiles.jsx");

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  // Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  Filter: () => <div />,
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const statusPriorityMap = {
  Submitted: 1,
  "User Submitted": 2,
  "Quote Generated": 3,
  "Uploaded Document": 4,
  "UW Questions": 5,
  MQL: 6,
  SQL: 7,
  "Company Profile(Get Quote)": 8,
  "Company Profile": 9,
  "Landing Page": 10,
  "Visited Website": 11,
  "Affinity Logo Clicked": 12,
  "Fourth Logo Clicked": 13,
  "Clicked on Affinity logo": 14,
  "Clicked on Fourth logo": 15,
  "Clicked on the image": 16,
  "Clicked on insure comp logo": 17,
  "Watched Video": 18,
  "Visited LinkedIn": 19,
  "Visited Facebook": 20,
  "Visited Instagram": 21,
  "Accessed Social Media": 22,
  "Opened Email": 23,
  "Not Campaigned": 24,
  "-": 999,
};

let selectedRows = [];
let initialcomment = {
  comment: "",
  commentTimestamp: 0,
  email: "",
};

class DashboardAdmin extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isLoading: true,
      program: "PEO",
      source: "Affinity",
      style: {},
      uploadDateFlag: false,
      sourceSelected: "All",
      confirmAllLoading: false,
      popupData: {
        title: "",
        children: <></>,
        acceptBtn: "",
        acceptBtnCallback: () => {},
        rejectBtnCallback: () => {},
        show: false,
      },
      modalBody: [],
      openModal: false,
      scrollLeft: false,
      scrollRight: true,
      adminHistoryComment: {},
      engagedCommentHistory: {},
      campaignedCommentsHistory: {},
      batchCount: 0,
      dataLoading: true,
    };
    this.handleDisplayHide = this.handleDisplayHide.bind(this);
    console.log(this.props);
  }

  fetchDashboardData = () => {
    if (this.props.tabName === "tab3") {
      this.updateSalesDisplay();
    } else if (this.props.tabName === "tab1") {
      this.updateDisplayTab1();
    } else if (this.props.tabName === "tab2") {
      this.updateDisplayTab2();
    } else if (this.props.tabName === "tab4" || this.props.tabName === "tab5") {
      // this.setState({ isLoading: this.props.isLoading });
      console.log(this.props.isLoading);
      this.setState({ dataLoading: false });
    }
  };

  updatePopUpData = (popUp) => {
    this.setState({ popupData: popUp });
  };

  handleSelection = (rows) => {
    selectedRows = rows;
    console.log(selectedRows.length);
  };

  handleConfirmAll = (tabName) => {
    let { dealMap } = this.props;
    if (selectedRows.length > 0) {
      this.updatePopUpData({
        show: true,
        title: "Do you wish to confirm the deals?",
        children: (
          <>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              Once confirmed, cannot be reverted.
            </span>
          </>
        ),
        acceptBtn: "Yes",
        acceptBtnCallback: async () => {
          try {
            let promiseList = [];
            let confirmAllDataList = [];
            let i = 1;
            this.setState({ confirmAllLoading: true });
            for (let row of selectedRows) {
              let key = row.domain || row.salesID;

              let dealFlag;
              if (dealMap[key] === "true") {
                dealFlag = "closed";
              } else if (dealMap[key] === "false") {
                dealFlag = "lost";
              } else {
                dealFlag = row.dealFlag;
              }

              if (
                (row.dealFlag === "lost" ||
                  row.dealFlag === "closed" ||
                  dealMap[key] === "true" ||
                  dealMap[key] === "false") &&
                (!row.dealConfirmed || row?.dealConfirmed !== "true")
              ) {
                let api_body = {
                  salesId: row?.recordDetails?.salesId || "",
                  domain: row?.recordDetails?.domain || "",
                  userId: row?.recordDetails?.userId || "",
                  isMailCmpn: row?.recordDetails?.isMailCmpn || false,
                  uuidList: row?.recordDetails?.uuidList || [],
                  confirmDeal: "true",
                };

                confirmAllDataList.push({
                  tab: tabName,
                  index: Number(row.tableData.id),
                  dealFlag,
                  rowData: row,
                  key:
                    row?.recordDetails?.domain || row?.recordDetails?.salesId,
                  confirmDeal: "true",
                });
                let header = await getHeader();
                promiseList.push(
                  axios
                    .post(
                      awsUrl + "/api/setDealConfirmed",
                      JSON.stringify(api_body),
                      header
                    )
                    .catch((err) => console.log(err))
                );

                if (i % 10 === 0) {
                  await Promise.all(promiseList);
                  promiseList = [];
                }
                i++;
              }
            }

            if (promiseList.length > 0) {
              await Promise.all(promiseList);
            }

            this.setState({ confirmAllLoading: false });
            this.props.handleDealConfirmAll(confirmAllDataList);
            for (let data of confirmAllDataList) {
              this.props.updateConfirmDealMap(data.key, data.confirmDeal);
            }
          } catch (error) {
            console.log("error: ", error);
          }
        },
      });
    } else {
      this.updatePopUpData({
        show: true,
        title: "Nothing to confirm.",
        children: (
          <>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              Please select rows to confirm deals.
            </span>
          </>
        ),
        acceptBtn: "Okay",
        acceptBtnCallback: async () => {},
        disableNoButton: true,
      });
    }
  };

  handleDisplayHide = () => {
    let { tabName, selected } = this.props;
    this.setState({
      style: tabName !== selected ? { display: "none", fontSize: "12px" } : {},
    });
  };

  getUploadDateFlag = async () => {
    const currentAuthUser = await Auth.currentAuthenticatedUser();
    if (currentAuthUser) {
      let groups =
        currentAuthUser?.signInUserSession?.accessToken?.payload[
          "cognito:groups"
        ];
      if (groups.includes(process.env.REACT_APP_DATA_TEAM)) return true;
      else return false;
    }
  };

  updateUploadFlag = async () => {
    let flag = await this.getUploadDateFlag();
    this.setState({ uploadDateFlag: flag });
  };

  componentDidMount() {
    this.fetchDashboardData();
    this.updateUploadFlag();
    window.addEventListener("load", this.handleDisplayHide);
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      this.handleDisplayHide();
    }
  }

  updateDomainMessageidFiles = (domain, key, val) => {
    let { domain_messageid_files } = JSON.parse(JSON.stringify(this.state));
    domain_messageid_files[domain][key] = val;
    this.setState({ domain_messageid_files });
  };

  appendRemaining = (str) => {
    let len = str.length;
    for (let i = 1; i <= 10 - len; i++) {
      str = "0" + str;
    }
    return str;
  };

  //Function to Update tab1 , tab2, tab3 data
  updateAdminComment = (data, rowData) => {
    let { rowDataList } = JSON.parse(JSON.stringify(this.props));
    let index = rowData.tableData.id;
    let requestBody = {};

    requestBody.domain = data.id ? data.id : data.domain;
    requestBody.sortKeyList = data.sortKeyList;

    requestBody.admincomment = data.admincomment;
    if (this.props.tabName === "tab2") {
      if (rowDataList[index].tableName === "userTrackingTable")
        requestBody.tableName = "tab1";
      else requestBody.tableName = "tab2";
    } else {
      requestBody.tableName = this.props.tabName;
    }

    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {
        console.log("Data saved", data);
      })
      .catch((error) => {
        console.log("error in posting comment", error);
      });
  };

  //Function to update won and lost tabs
  updateAdminWonLossComment = (data, rowData) => {
    let requestBody = {};
    requestBody.domain = data.id ? data.id : data.domain;
    requestBody.admincomment = data.admincomment;
    requestBody.tableName = rowData.tab;
    requestBody.sortKeyList = data.sortKeyList;
    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {
        console.log("Data saved", data);
      })
      .catch((error) => {
        console.log("error in posting comment", error);
      });
  };

  updateComment = (e, rowData) => {
    let { program, source } = JSON.parse(JSON.stringify(this.state));
    let { rowDataList } = JSON.parse(JSON.stringify(this.props));
    let index = rowData.tableData.id;
    let requestBody = {};

    requestBody.domain = rowDataList[index].domain;
    requestBody.comment = e.target.value;
    if (this.props.tabName === "tab2") {
      if (rowDataList[index].tableName === "campaignTable")
        requestBody.tableName = "tab1";
      else requestBody.tableName = "tab2";
    } else {
      requestBody.tableName = this.props.tabName;
    }

    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  updateDiscountData = (rowData) => {
    let requestBody = {
      user_email_id: rowData.userEmail,
      uuid_carrier: rowData.uuid_carrier,
      discountData: rowData.discountData,
    };
    axios
      .post(awsUrl2 + "/api/updateUserStatusTable", requestBody)
      .then((res) => {})
      .catch((err) => console.log("error: ", err));
  };

  camelCase = (str) => {
    if (str)
      return str
        .trim()
        .split(" ")
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(" ");
    return "";
  };

  formatFein = (value) => {
    let fein;
    value = value.split("-").join("");
    var len = value.length,
      dummystr = "000000000";

    if (len === 0) {
      fein = "-";
    } else if (len > 0 && len < 9) {
      value = dummystr.slice(0, 9 - len) + "" + value;
      fein = value[0] + value[1] + "-" + value.slice(2);
    } else {
      fein = value[0] + value[1] + "-" + value.slice(2);
    }

    return fein;
  };

  onClickCompanyName = (rowData) => {
    sessionStorage.clear();
    if (rowData.currProspectDetails) {
      sessionStorage.setItem(
        "currProspect",
        JSON.stringify(rowData.currProspectDetails)
      );
    }
    sessionStorage.setItem("hideButtons", "true");
    if (rowData.salesID) {
      sessionStorage.setItem("salesID", rowData.salesID);
    }
    if (rowData.formStage) {
      if (rowData.formStage !== "one" && rowData.formStage !== "two")
        sessionStorage.setItem("formStage", "three");
      else sessionStorage.setItem("formStage", rowData.formStage);
    }
    if (rowData.amtrustAccountDetails) {
      sessionStorage.setItem(
        "amtrustAccountDetails",
        JSON.stringify(rowData.amtrustAccountDetails)
      );
    }
    if (rowData.address) {
      sessionStorage.setItem("address", JSON.stringify(rowData.address));
    }
    if (rowData.quoteData) {
      sessionStorage.setItem("quoteData", JSON.stringify(rowData.quoteData));
    }
    if (rowData.sortKeyList) {
      sessionStorage.setItem(
        "sortKeyList",
        JSON.stringify(rowData.sortKeyList)
      );
    }
    sessionStorage.setItem("showAllFields", true);
  };

  dateSort = (a, b, field) => {
    let momentA = moment(a[field], "M/D/YYYY - hh:mm A");
    let momentB = moment(b[field], "M/D/YYYY - hh:mm A");
    if (
      (!momentA.isValid() && momentB.isValid()) ||
      momentA.isBefore(momentB)
    ) {
      return -1;
    } else if (
      (momentA.isValid() && !momentB.isValid()) ||
      momentA.isAfter(momentB)
    ) {
      return 1;
    } else if (
      (!momentA.isValid() && !momentB.isValid()) ||
      momentA.isSame(momentB)
    ) {
      return 0;
    }
  };

  formatInCamel = (str) => {
    if (str) {
      return str[0].toUpperCase() + "" + str.slice(1);
    }
    return str;
  };

  fontfunciton = () => {
    if (window.innerWidth < 1600 && window.innerWidth > 1500) {
      return "0.92rem";
    }
    if (window.innerWidth < 1500 && window.innerWidth > 1410) {
      return "0.9rem";
    }
    if (window.innerWidth < 1410 && window.innerWidth > 1310) {
      return "0.88rem";
    }
    if (window.innerWidth < 1300) {
      return "0.85rem";
    }
  };

  customFilterfunction = (term, rowData, field) => {
    let customFieldName = field["field"];

    let excludeFirstCharacter = term.substring(1);
    let excludeFirstTwoCharacter = term.substring(2);
    let firstCharacter = term.substring(1, 0);
    let firstTwoCharacter = term.substring(2, 0);
    let operationMap = {
      ">=": ">=",
      "<=": "<=",
      "<": "<",
      ">": ">",
      "=": "===",
    };
    try {
      if (firstTwoCharacter in operationMap) {
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstTwoCharacter]} ${excludeFirstTwoCharacter}`
        );
      } else if (firstCharacter in operationMap) {
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstCharacter]} ${excludeFirstCharacter}`
        );
      } else {
        return rowData[customFieldName]?.toString()?.startsWith(term);
      }
    } catch (err) {
      return rowData[customFieldName]?.toString()?.startsWith(term);
    }
  };

  customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];

    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };
  scrollLeft = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));
    const tableContainerDiv = this.myRef.current.lastChild.lastChild.children[2]
      .children[1].children[0].children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft -= 150;
      if (tableContainerDiv.scrollLeft == 0) {
        this.setState({ scrollLeft: false, scrollRight: true });
      }

      const maxScrollLeft =
        tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

      if (tableContainerDiv.scrollLeft < maxScrollLeft) {
        this.setState({ scrollRight: true });
      }
    }
  };
  scrollRight = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));

    const tableContainerDiv = this.myRef.current.lastChild.lastChild.children[2]
      .children[1].children[0].children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft += 150;
      this.setState({ scrollLeft: true });
    }

    const maxScrollLeft =
      tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

    if (tableContainerDiv.scrollLeft > maxScrollLeft) {
      this.setState({ scrollRight: false });
    }
  };

  tab1DetailPanel = (rowData) => {
    return (
      <div style={{ padding: "25px" }}>
        <TableContainer component={Paper}>
          <Table size="small" className="sub_tab1">
            <TableHead
              style={{
                background: "#00000066",
                color: "whitesmoke",
              }}
            >
              <TableRow>
                <TableCell>Contact Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Job Description</TableCell>
                <TableCell>Visit Count</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.email_specific ? (
                Object.values(rowData.email_specific).map((element, index) => {
                  let step;
                  if (element.companyProfile) {
                    if (Number(element.proposalGeneratedCount) > 0) {
                      step = "Proposal Generated";
                    } else if (element.quote_status) {
                      if (element.quote_status === "uwportal") {
                        step = "UW Portal";
                      } else if (element.quote_status === "proceed_uw") {
                        step = "Approved";
                      } else if (element.quote_status === "reject") {
                        step = "Rejected";
                      }
                    } else if (Number(element.quoteGeneratedCount) > 0) {
                      step = "Quote Generated";
                    } else if (element.formStage) {
                      if (element.formStage === "two") {
                        if (element.nonEligibilityList) {
                          step = "Not Eligible";
                        } else {
                          step = "UW Questions";
                        }
                      } else if (element.formStage === "one") {
                        step = "Company Profile";
                      }
                    } else if (
                      element.visitCount &&
                      Number(element.visitCount) > 0
                    ) {
                      step = "Landing Page";
                    } else {
                      step = "-";
                    }
                    if (
                      element.companyProfile.status &&
                      element.companyProfile.status.value
                    ) {
                      step =
                        statusPriorityMap[element.companyProfile.status.value] <
                        statusPriorityMap[step]
                          ? element.companyProfile.status.value
                          : step;
                    }
                    return (
                      <TableRow key={index}>
                        <TableCell scope="row">
                          {element.companyProfile &&
                          element.companyProfile.contact_name
                            ? element.companyProfile.contact_name.value
                            : ""}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.companyProfile?.phoneNumber?.value?.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            "($1) $2-$3"
                          ) ||
                            element?.companyProfile?.contact_number?.value?.replace(
                              /(\d{3})(\d{3})(\d{4})/,
                              "($1) $2-$3"
                            ) ||
                            ""}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.companyProfile?.emailId?.value}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.companyProfile?.job_title?.value}
                        </TableCell>
                        <TableCell scope="row">
                          {element.visitCount || 0}
                        </TableCell>
                        <TableCell scope="row">{step}</TableCell>
                      </TableRow>
                    );
                  }
                })
              ) : (
                <TableRow key={0}>
                  <TableCell scope="row">{rowData.contactName}</TableCell>
                  <TableCell scope="row">
                    {rowData.phoneNumber
                      ? rowData.phoneNumber.replace(
                          /(\d{3})(\d{3})(\d{4})/,
                          "($1) $2-$3"
                        )
                      : "-"}
                  </TableCell>
                  <TableCell scope="row">{rowData.userEmail}</TableCell>
                  <TableCell scope="row">{""}</TableCell>
                  <TableCell scope="row">{rowData.visitCount}</TableCell>
                  {/* <TableCell scope="row">
                {rowData.latestCampaign || ""}
              </TableCell> */}
                  <TableCell scope="row">{rowData.step || ""}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  tab2DetailPanel = (rowData) => {
    return (
      <div style={{ padding: "25px" }}>
        <TableContainer component={Paper}>
          <Table size="small" className="sub_tab2">
            <TableHead
              style={{
                background: "#00000066",
                color: "whitesmoke",
              }}
            >
              <TableRow>
                <TableCell>Contact Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Job Description</TableCell>
                <TableCell>Visit Count</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.email_specific ? (
                Object.values(rowData.email_specific).map((element, index) => {
                  let step;
                  if (element.companyProfile) {
                    if (Number(element.proposalGeneratedCount) > 0) {
                      step = "Proposal Generated";
                    } else if (element.quote_status) {
                      if (element.quote_status === "uwportal") {
                        step = "UW Portal";
                      } else if (element.quote_status === "proceed_uw") {
                        step = "Approved";
                      } else if (element.quote_status === "reject") {
                        step = "Rejected";
                      }
                    } else if (Number(element.quoteGeneratedCount) > 0) {
                      step = "Quote Generated";
                    } else if (element.formStage) {
                      if (element.formStage === "two") {
                        if (element.nonEligibilityList) {
                          step = "Not Eligible";
                        } else {
                          step = "UW Questions";
                        }
                      } else if (element.formStage === "one") {
                        step = "Company Profile";
                      }
                    } else if (
                      element.visitCount &&
                      Number(element.visitCount) > 0
                    ) {
                      step = "Landing Page";
                    } else {
                      step = "-";
                    }
                    if (
                      element.companyProfile.status &&
                      element.companyProfile.status.value
                    ) {
                      step =
                        statusPriorityMap[element.companyProfile.status.value] <
                        statusPriorityMap[step]
                          ? element.companyProfile.status.value
                          : step;
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell scope="row">
                          {element.companyProfile &&
                          element.companyProfile.contact_name
                            ? element.companyProfile.contact_name.value
                            : ""}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.companyProfile?.phoneNumber?.value?.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            "($1) $2-$3"
                          ) ||
                            element?.companyProfile?.contact_number?.value?.replace(
                              /(\d{3})(\d{3})(\d{4})/,
                              "($1) $2-$3"
                            ) ||
                            ""}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.companyProfile?.emailId?.value}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.companyProfile?.job_title?.value}
                        </TableCell>
                        <TableCell scope="row">
                          {element.visitCount || 0}
                        </TableCell>
                        <TableCell scope="row">{step}</TableCell>
                      </TableRow>
                    );
                  }
                })
              ) : (
                <TableRow key={0}>
                  <TableCell scope="row">{rowData.contactName}</TableCell>
                  <TableCell scope="row">
                    {rowData.phoneNumber
                      ? rowData.phoneNumber.replace(
                          /(\d{3})(\d{3})(\d{4})/,
                          "($1) $2-$3"
                        )
                      : "-"}
                  </TableCell>
                  <TableCell scope="row">{rowData.userEmail}</TableCell>
                  <TableCell scope="row">{""}</TableCell>
                  <TableCell scope="row">{rowData.visitCount}</TableCell>
                  {/* <TableCell scope="row">
                {rowData.latestCampaign || ""}
              </TableCell> */}
                  <TableCell scope="row">{rowData.step || ""}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  tab3DetailPanel = (rowData) => {
    return (
      <div style={{ padding: "25px" }}>
        <TableContainer component={Paper}>
          <Table size="small" className="sub_tab3">
            <TableHead
              style={{
                background: "#00000066",
                color: "whitesmoke",
              }}
            >
              <TableRow>
                <TableCell>Contact Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Job Description</TableCell>
                <TableCell>Visit Count</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                <TableRow key={0}>
                  <TableCell scope="row">
                    {(this.formatInCamel(
                      rowData?.companyProfile?.firstName?.value
                    ) || "") +
                      " " +
                      (this.formatInCamel(
                        rowData?.companyProfile?.lastName?.value
                      ) || "")}
                  </TableCell>
                  <TableCell scope="row">
                    {rowData?.companyProfile?.phoneNumber?.value?.replace(
                      /(\d{3})(\d{3})(\d{4})/,
                      "($1) $2-$3"
                    ) || "-"}
                  </TableCell>
                  <TableCell scope="row">{"-"}</TableCell>
                  <TableCell scope="row">{"-"}</TableCell>
                  <TableCell scope="row">{"0"}</TableCell>
                  <TableCell scope="row">{rowData.step || ""}</TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  convertToTimestamp = (submittedDate) => {
    let timestamp;
    if (moment(submittedDate, "M/D/YYYY - h:mm A", true)?.isValid()) {
      timestamp = moment(submittedDate, "M/D/YYYY - h:mm A")?.valueOf();
    } else if (moment(submittedDate)?.isValid()) {
      timestamp = moment(submittedDate)?.valueOf();
    } else {
      console.log("Invalid timestamp format");
    }
    return timestamp;
  };

  //function to update Admin modalbody state to display comment history modal
  handleAdminCommentsButtonClick = (comments, submittedDate, id) => {
    let timestamp = this.convertToTimestamp(submittedDate);

    if (typeof comments?.[comments?.length - 1] === "string") {
      let updatedComment = comments?.slice(0, -1); // Copy all comments except the last one
      updatedComment.push({
        // Push the updated last comment
        ...initialcomment,
        comment: comments?.[comments?.length - 1],
        commentTimestamp: timestamp,
      });
      this.setState({ modalBody: updatedComment });
    } else {
      this.setState({ modalBody: comments });
    }
    this.setState({ openModal: true });
  };

  //function to update others disable comments modalbody state to display comment history modal
  handleCommentHistory = (rowdata, rowkey, name) => {
    rowdata.forEach((item) => {
      if (item.salesID || item.domain === rowkey) {
        let submittedDate;
        if (item.visitDate) {
          submittedDate = item.visitDate;
        } else {
          submittedDate = item.uploadedTimeStamp;
        }
        let timestamp = this.convertToTimestamp(submittedDate);
        if (typeof item[name]?.[item[name]?.length - 1] === "string") {
          let updatedComment = item[name]?.slice(0, -1); // Copy all comments except the last one
          updatedComment.push({
            // Push the updated last comment
            ...initialcomment,
            comment: item[name]?.[item[name].length - 1],
            commentTimestamp: timestamp,
          });
          this.setState({ modalBody: updatedComment });
        } else {
          this.setState({ modalBody: item[name] });
        }
      }
    });
    this.setState({ openModal: true });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  setCommentBoxColor = (comments) => {
    // if (comments?.[0] !== "") {
    //   if (typeof comments?.[0] === "string") {
    //     return "#FFE7C1";
    //   }
    // }
    if (comments?.[0]?.comment === "") {
      if (comments?.length > 1) {
        return "#FFE7C1";
      }
    } else if (comments?.length > 0) {
      return "#FFE7C1";
    }
  };

  salesIDCheck = async (e, rowData) => {
    // debugger;
    var value = e.target.value;
    let requestBody = { id: value };

    let salesResponse = await axios.post(
      awsUrl + `/api/sendToSaleforceCheck`,
      requestBody
    );

    let objectBody = {};
    if (salesResponse.data || salesResponse.data === "true") {
      objectBody.domain = rowData.salesID || rowData.id || rowData.domain;
      objectBody.salesIDCheck = value;
      let updateDataRes = await axios.post(
        awsUrl + "/api/uploadSalesforceID",
        objectBody
      );

      if (updateDataRes.data === "successfuly updated") {
        toast.success("Successfully ID uploaded");
      } else {
        toast.error("Failed to upload ID", {
          duration: 3000,
        });
      }
    } else {
      toast.error("Quote ID does exist in Saleforce", {
        duration: 3000,
      });
    }
  };

  setTableData = () => {
    let {
      isLoading,
      domain_messageid_files,
      discountDataObject,
      program,
      source,
      uploadDateFlag,
      sourceSelected,
      scrollLeft,
      scrollRight,
      adminHistoryComment,
      engagedCommentHistory,
      campaignedCommentsHistory,
      popupData,
    } = JSON.parse(JSON.stringify(this.state));
    let {
      rowDataList,
      tabName,
      dealMap,
      updateDealMap,
      confirmDealMap,
      updateConfirmDealMap,
      handleDealConfirm,
      wonListCommentsHistory,
      lostListCommentsHistory,
    } = this.props;

    const putSource = (Source) => {
      if (Source == "hotschedules") {
        return "HotSchedules";
      } else if (Source == "affinity") {
        return "Affinity";
      } else {
        return Source;
      }
    };

    const updateHistoryComments = (id, comment, rowData) => {
      this.setState((prevstate) => {
        if (rowData.tab === "tab3") {
          let updateadminHistoryComment = JSON.parse(
            JSON.stringify(prevstate.adminHistoryComment)
          );
          updateadminHistoryComment[id] = comment;
          return { adminHistoryComment: updateadminHistoryComment };
        }
        if (rowData.tab === "tab1") {
          let updateEngagedHistoryComment = JSON.parse(
            JSON.stringify(prevstate.engagedCommentHistory)
          );
          updateEngagedHistoryComment[id] = comment;
          return { engagedCommentHistory: updateEngagedHistoryComment };
        }
        if (rowData.tab === "tab2") {
          let updateCampaignedHistoryComment = JSON.parse(
            JSON.stringify(prevstate.campaignedCommentsHistory)
          );
          updateCampaignedHistoryComment[id] = comment;
          return {
            campaignedCommentsHistory: updateCampaignedHistoryComment,
          };
        }
      });
    };

    let columns = {
      tab1: [
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          filterPlaceholder: "Filter",
          defaultSort: "desc",
          customSort: (a, b) => this.dateSort(a, b, "visitDate"),
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => {
            if (rowData.visitDate) {
              let date = rowData.visitDate.split("-")[0];
              let time = rowData.visitDate.split("-")[1];
              return (
                <span>
                  {date} {"&"}
                  {time}
                </span>
              );
            } else {
              return "-";
            }
          },
          cellStyle: {
            wordBreak: "break-all",
            padding: "3px 0",
            minWidth: "10rem",
          },
          headerStyle: {
            padding: "3px 0px 3px 4px",
            minWidth: "10rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          filterPlaceholder: "Filter",

          render: (rowData) => {
            let random_user = Object.keys(rowData?.email_specific || {})[0];

            return (
              <Clamp lines={2}>
                <span
                  onClick={() => {
                    sessionStorage.setItem("hideButtons", "true");
                    sessionStorage.setItem("showAllFields", true);
                    sessionStorage.setItem("salespersonMailCampaign", true);
                  }}
                >
                  <Link to={`/Quote/?user=${random_user}&viewButton=true`}>
                    {this.camelCase(rowData?.companyName || "-")}
                  </Link>
                </span>
              </Clamp>
            );
          },
          cellStyle: {
            padding: "6px 5px 6px 10px",
            minWidth: "12rem",
            wordBreak: "break-word",
          },
          headerStyle: {
            padding: "3px 0px 3px 10px",
            minWidth: "12rem",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 4px",
          },
        },
        {
          title: "Payroll",
          field: "payroll",
          filterPlaceholder: "Filter",
          render: (rowData) => numeral(rowData?.payroll).format("$0,0"),
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 10px 6px 10px",
            minWidth: "8rem",
            wordBreak: "break-all",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },

          filterCellStyle: {
            padding: "2px 18px 2px 0px",
          },
        },
        {
          title: "State",
          field: "state",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "City",
          field: "city",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => this.camelCase(rowData.city),
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          filterCellStyle: {
            padding: "2px 7px 2px 1px",
          },
        },
        {
          title: "# Emp",
          field: "total_emp",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 0",
            paddingLeft: "20px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            whiteSpace: "nowrap",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 15px",
          },
        },
        {
          title: "Status",
          field: "step",
          filterPlaceholder: "Filter",

          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px", minWidth: "8rem" };
            if (rowData.step === "Submitted") {
              style.background = "#5ADA38";
            } else if (rowData.step === "User Submitted") {
              style.background = "#5ADA38";
            } else if (rowData.step === "Proposal Generated") {
              style.backgroundColor = "#5ADA38";
            } else if (rowData.step === "UW Portal") {
              style.backgroundColor = "#ffd737ac";
            } else if (rowData.step === "Approved") {
              style.backgroundColor = "#87FA4D";
            } else if (rowData.step === "Rejected") {
              style.backgroundColor = "#fa6d3e";
            } else if (rowData.step === "Quote Generated") {
              style.backgroundColor = "#87FA20";
            } else if (rowData.step === "UW Questions") {
              style.backgroundColor = "#FFD737";
            } else if (Number(rowData.visitCount) > 0) {
              style.backgroundColor = "#FFD737";
            } else {
              style.backgroundColor = "#FFF";
            }
            return style;
          },
          headerStyle: {
            padding: "6px 0 6px 13px",
            minWidth: "8rem",
          },
          filterCellStyle: {
            padding: "2px 5px 2px 5px",
          },
        },
        {
          title: "Comments",
          field: "admincomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            const callbackFunction = (dataToSave, rowData) => {
              if (dataToSave) this.updateAdminComment(dataToSave, rowData);
            };
            return (
              <div className="d-flex">
                <TextField
                  key={`${rowData?.salesID || rowData?.domain}_comment`}
                  value={
                    typeof engagedCommentHistory?.[rowData?.salesID]?.[0] ===
                      "object" ||
                    typeof engagedCommentHistory?.[rowData?.domain]?.[0] ===
                      "object"
                      ? engagedCommentHistory?.[rowData?.salesID]?.[0]
                          ?.comment ||
                        engagedCommentHistory?.[rowData?.domain]?.[0]?.comment
                      : engagedCommentHistory?.[rowData?.salesID]?.[0] ||
                        engagedCommentHistory?.[rowData?.domain]?.[0]
                  }
                  callback={callbackFunction}
                  UpdateCommentsHistoryState={updateHistoryComments}
                  rowData={rowData}
                  rowDataID={rowData?.salesID || rowData?.domain}
                  rowDataName={rowData?.domain ? "domain" : "id"}
                  rowDataSortKeyList={rowData?.sortKeyList}
                  rowDataComments={"admincomment"}
                  comment={
                    engagedCommentHistory?.[rowData?.salesID] ||
                    engagedCommentHistory?.[rowData?.domain]
                  }
                  // handleAgentCommentHistory={this.handleAgentCommentHistory}
                />
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleAdminCommentsButtonClick(
                          engagedCommentHistory?.[rowData?.salesID] ||
                            engagedCommentHistory?.[rowData?.domain],
                          rowData.visitDate || rowData.uploadedTimeStamp
                        )
                      // console.log("data", adminHistoryComment?.[rowData?.salesID])
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            minWidth: "230px",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 10px",
          },
        },
        {
          title: "Visit Count",
          field: "visitCount",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 0 6px 23px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0px 6px 15px",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 10px",
          },
        },
        {
          title: "PreUW?",
          field: "isPreUnderwritten",
          filterPlaceholder: "Filter",
          lookup: { true: "Yes", false: "No" },
          cellStyle: {
            padding: "6px 0 6px 20px",
            minWidth: "150px",
          },
          headerStyle: {
            padding: "6px 0px 6px 13px",
            minWidth: "150px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 10px",
          },
          render: (rowData) => (rowData?.isPreUnderwritten ? "YES" : "NO"),
        },
        {
          title: (
            <img
              src={require("../../images/file-download-circle-green.svg")}
              alt=""
              style={{
                width: "40px",
                // filter: "grayscale(100%)  opacity(0.4)",
              }}
            />
          ),
          disableSortBy: true,
          cellStyle: {
            minWidth: "30px",
            width: "30px",
            maxWidth: "30px",
            padding: "0 0",
          },
          headerStyle: {
            paddingLeft: "15px",
          },
          render: (rowData) => {
            let title = "";

            if (
              domain_messageid_files[rowData.domain]?.dashboardFiles?.length
            ) {
              for (let file of domain_messageid_files[rowData.domain]
                .dashboardFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }
            if (
              domain_messageid_files[rowData.domain]?.fileListToStore?.length
            ) {
              for (let file of domain_messageid_files[rowData.domain]
                .fileListToStore) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }
            if (domain_messageid_files[rowData.domain]?.preUwFiles?.length) {
              for (let file of domain_messageid_files[rowData.domain]
                .preUwFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }

            let totalFiles =
              (domain_messageid_files[rowData.domain]?.dashboardFiles?.length ||
                0) +
              (domain_messageid_files[rowData.domain]?.preUwFiles?.length ||
                0) +
              (domain_messageid_files[rowData.domain]?.fileListToStore
                ?.length || 0);

            return (
              <button
                className="btn pt-0"
                type="button"
                title={parse(title)}
                data-html="true"
                onClick={() => {
                  this.setState({
                    showModel: true,
                    modalTitle: <h1>{"Download"}</h1>,
                    modalContent: (
                      <>
                        <DownloadDashboardFiles
                          dashboardFiles={
                            domain_messageid_files[rowData.domain]
                              .dashboardFiles || []
                          }
                          preUwFiles={
                            domain_messageid_files[rowData.domain].preUwFiles ||
                            []
                          }
                          fileListToStore={
                            domain_messageid_files[rowData.domain]
                              .fileListToStore || []
                          }
                          domainName={rowData.domain}
                          updateDomainMessageidFiles={
                            this.updateDomainMessageidFiles
                          }
                        />
                      </>
                    ),
                  });
                }}
              >
                <img
                  src={require("../../images/file-download-circle-green.svg")}
                  alt=""
                  style={
                    totalFiles > 0
                      ? {
                          width: "40px",
                          filter: "grayscale(0)",
                        }
                      : {
                          width: "40px",
                          filter: "grayscale(100%)  opacity(0.4)",
                        }
                  }
                />
              </button>
            );
          },
        },
        {
          title: "Campaign Name",
          field: "latestCampaign",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-word",
            minWidth: "180px",
          },
          headerStyle: {
            minWidth: "180px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {this.camelCase(rowData?.latestCampaign) || ""}
            </Clamp>
          ),
        },
        {
          title: "Salesperson",
          field: "salespersonName",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "170px",
          },
          headerStyle: {
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => {
            let name = "";
            try {
              if (rowData?.salespersonName?.length > 15) {
                name = `${rowData.salespersonName?.slice(0, 15)}...`;
              } else {
                name = rowData?.salespersonName;
              }
            } catch (error) {}
            return <Clamp lines={2}>{rowData?.name || " "}</Clamp>;
          },
        },
        {
          title: "Source",
          field: "source",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "170px",
          },
          headerStyle: {
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 5px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{putSource(rowData?.source) || ""}</Clamp>
          ),
        },
        {
          title: "SalesPerson Comments",
          field: "salescomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.salescomment?.[0] === "object"
                      ? rowData?.salescomment?.[0]?.comment
                      : rowData?.salescomment?.[0] ||
                        rowData?.salescomment ||
                        ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(
                      rowData?.salescomment
                    ),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "salescomment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        {
          title: "PreUW Comments",
          field: "comment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.comment?.[0] === "object"
                      ? rowData?.comment?.[0]?.comment
                      : rowData?.comment?.[0] || rowData?.comment || ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(rowData?.comment),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "comment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        {
          title: "SM Comments",
          field: "smcomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.smcomment?.[0] === "object"
                      ? rowData?.smcomment?.[0]?.comment
                      : rowData?.smcomment?.[0] || rowData?.smcomment || ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(
                      rowData?.smcomment
                    ),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "smcomment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        {
          title: "Deal Won/Lost",
          field: "dealFlagFilter",
          filtering: true,
          filterPlaceholder: "Filter",
          render: (rowData) => {
            let key = rowData.domain || rowData.salesID;
            let isClosed = false,
              isLost = false;
            if (key in dealMap) {
              isClosed = dealMap[key] === "true" ? true : false;
              isLost = dealMap[key] === "false" ? true : false;
            } else {
              isClosed = rowData.dealFlag === "closed" ? true : false;
              isLost = rowData.dealFlag === "lost" ? true : false;
            }
            let confirm = false;
            if (confirmDealMap?.[key] === "true") confirm = true;
            else if (rowData.dealConfirmed === "true") confirm = true;
            return (
              <DealClosedLostButtons
                recordDetails={rowData.recordDetails}
                isClosed={isClosed}
                isLost={isLost}
                updateDealMap={updateDealMap}
                disabled={confirm}
              />
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "150px",
          },
          headerStyle: {
            padding: "6px 5px 6px 20px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 15px",
          },
        },
        {
          title: "Confirm Deal",
          field: "dealConfirmed",
          filtering: false,
          render: (rowData) => {
            let key = rowData.domain || rowData.salesID;
            let isClosed = false,
              isLost = false;
            if (key in dealMap) {
              isClosed = dealMap[key] === "true" ? true : false;
              isLost = dealMap[key] === "false" ? true : false;
            } else {
              isClosed = rowData.dealFlag === "closed" ? true : false;
              isLost = rowData.dealFlag === "lost" ? true : false;
            }
            let confirm = false;
            if (confirmDealMap?.[key] === "true") confirm = true;
            else if (rowData.dealConfirmed === "true") confirm = true;

            return (
              <ConfirmDealButton
                updatePopUpData={this.updatePopUpData}
                rowData={rowData}
                isClosed={isClosed}
                isLost={isLost}
                updateConfirmDealMap={updateConfirmDealMap}
                disabled={confirm}
                handleDealConfirm={handleDealConfirm}
                tabName={tabName}
              />
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "150px",
          },
          headerStyle: {
            padding: "6px 5px 6px 20px",
          },
        },
      ],
      tab2: [
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          filterPlaceholder: "Filter",
          defaultSort: "desc",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),

          customSort: (a, b) => this.dateSort(a, b, "visitDate"),
          render: (rowData) => {
            if (rowData.visitDate) {
              let date = rowData.visitDate.split("-")[0];
              let time = rowData.visitDate.split("-")[1];
              return (
                <span>
                  {date} {"&"}
                  {time}
                </span>
              );
            } else {
              return "-";
            }
          },
          cellStyle: {
            wordBreak: "break-all",
            padding: "3px 3px",
            minWidth: "10rem",
          },
          headerStyle: {
            padding: "3px 0px 3px 6px",
            minWidth: "10rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          filterPlaceholder: "Filter",

          cellStyle: {
            padding: "6px 5px 6px 10px",
            minWidth: "12rem",
            wordBreak: "break-word",
          },
          headerStyle: {
            padding: "3px 0px 3px 10px",
            minWidth: "12rem",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 4px",
          },

          render: (rowData) => {
            let random_user = Object.keys(rowData?.email_specific || {})[0];

            return (
              <Clamp lines={2}>
                <span
                  onClick={() => {
                    sessionStorage.setItem("hideButtons", "true");
                    sessionStorage.setItem("showAllFields", true);
                    sessionStorage.setItem("salespersonMailCampaign", true);
                  }}
                >
                  <Link to={`/Quote/?user=${random_user}&viewButton=true`}>
                    {this.camelCase(rowData?.companyName || "-")}
                  </Link>
                </span>
              </Clamp>
            );
          },
        },
        {
          title: "Payroll",
          field: "payroll",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          render: (rowData) => numeral(rowData.payroll).format("$0,0"),
          cellStyle: {
            padding: "6px 10px 6px 10px",
            minWidth: "8rem",
            wordBreak: "break-all",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "7rem",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 0px",
          },
        },
        {
          title: "State",
          field: "state",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          filterPlaceholder: "Filter",
          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "City",
          field: "city",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => this.camelCase(rowData.city),
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          filterCellStyle: {
            padding: "2px 7px 2px 1px",
          },
        },
        {
          title: "# Emp",
          field: "total_emp",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0",
            paddingLeft: "20px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            whiteSpace: "nowrap",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 15px",
          },
        },
        {
          title: "Status",
          field: "step",
          filterPlaceholder: "Filter",

          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px", minWidth: "8rem" };
            if (rowData.step === "Submitted") {
              style.background = "#5ADA38";
            } else if (rowData.step === "Proposal Generated") {
              style.backgroundColor = "#5ADA38";
            } else if (rowData.step === "UW Portal") {
              style.backgroundColor = "#ffd737ac";
            } else if (rowData.step === "Approved") {
              style.backgroundColor = "#87FA4D";
            } else if (rowData.step === "Rejected") {
              style.backgroundColor = "#fa6d3e";
            } else if (rowData.step === "Quote Generated") {
              style.backgroundColor = "#87FA20";
            } else if (rowData.step === "UW Questions") {
              style.backgroundColor = "#FFD737";
            } else if (Number(rowData.visitCount) > 0) {
              style.backgroundColor = "#FFD737";
            } else {
              style.backgroundColor = "#FFF";
            }
            return style;
          },
          headerStyle: {
            padding: "6px 0 6px 13px",
            minWidth: "8rem",
          },
          filterCellStyle: {
            padding: "2px 5px 2px 5px",
          },
        },
        {
          title: "Comments",
          field: "admincomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            const callbackFunction = (dataToSave, rowData) => {
              if (dataToSave) this.updateAdminComment(dataToSave, rowData);
            };
            return (
              <div className="d-flex">
                <TextField
                  key={`${rowData?.domain}_comment`}
                  value={
                    typeof campaignedCommentsHistory?.[rowData?.domain]?.[0] ===
                    "object"
                      ? campaignedCommentsHistory?.[rowData?.domain]?.[0]
                          ?.comment
                      : campaignedCommentsHistory?.[rowData?.domain]?.[0]
                  }
                  callback={callbackFunction}
                  UpdateCommentsHistoryState={updateHistoryComments}
                  rowData={rowData}
                  rowDataID={rowData?.salesID || rowData?.domain}
                  rowDataName={rowData?.domain ? "domain" : "id"}
                  rowDataSortKeyList={rowData?.sortKeyList}
                  rowDataComments={"admincomment"}
                  comment={campaignedCommentsHistory?.[rowData?.domain]}
                  // handleAgentCommentHistory={this.handleAgentCommentHistory}
                />
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleAdminCommentsButtonClick(
                          campaignedCommentsHistory?.[rowData?.domain],
                          rowData?.visitDate || rowData.uploadedTimeStamp
                        )
                      // console.log("data", adminHistoryComment?.[rowData?.salesID])
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            minWidth: "230px",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 10px",
          },
        },
        {
          title: "Visit Count",
          field: "visitCount",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0 6px 23px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0px 6px 15px",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 10px",
          },
        },
        {
          title: "Pre UW?",
          field: "isPreUnderwritten",
          filterPlaceholder: "Filter",
          lookup: { true: "Yes", false: "No" },
          cellStyle: {
            padding: "6px 0 6px 20px",
            minWidth: "150px",
          },
          headerStyle: {
            padding: "6px 0px 6px 13px",
            minWidth: "150px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 10px",
          },
          render: (rowData) => (rowData.isPreUnderwritten ? "YES" : "NO"),
        },
        {
          title: (
            <img
              src={require("../../images/file-download-circle-green.svg")}
              alt=""
              style={{
                width: "40px",
                // filter: "grayscale(100%)  opacity(0.4)",
              }}
            />
          ),
          disableSortBy: true,
          cellStyle: {
            minWidth: "30px",
            width: "30px",
            maxWidth: "30px",
            padding: "0 0",
          },
          headerStyle: {
            paddingLeft: "15px",
          },
          render: (rowData) => {
            let title = "";

            if (
              domain_messageid_files[rowData.domain]?.dashboardFiles?.length
            ) {
              for (let file of domain_messageid_files[rowData.domain]
                .dashboardFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }
            if (
              domain_messageid_files[rowData.domain]?.fileListToStore?.length
            ) {
              for (let file of domain_messageid_files[rowData.domain]
                .fileListToStore) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }
            if (domain_messageid_files[rowData.domain]?.preUwFiles?.length) {
              for (let file of domain_messageid_files[rowData.domain]
                .preUwFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }

            let totalFiles =
              (domain_messageid_files[rowData.domain]?.dashboardFiles?.length ||
                0) +
              (domain_messageid_files[rowData.domain]?.preUwFiles?.length ||
                0) +
              (domain_messageid_files[rowData.domain]?.fileListToStore
                ?.length || 0);

            return (
              <button
                className="btn pt-0"
                type="button"
                title={parse(title)}
                data-html="true"
                onClick={() => {
                  this.setState({
                    showModel: true,
                    modalTitle: <h1>{"Download"}</h1>,
                    modalContent: (
                      <>
                        <DownloadDashboardFiles
                          dashboardFiles={
                            domain_messageid_files[rowData.domain]
                              .dashboardFiles || []
                          }
                          preUwFiles={
                            domain_messageid_files[rowData.domain].preUwFiles ||
                            []
                          }
                          fileListToStore={
                            domain_messageid_files[rowData.domain]
                              .fileListToStore || []
                          }
                          domainName={rowData.domain}
                          updateDomainMessageidFiles={
                            this.updateDomainMessageidFiles
                          }
                        />
                      </>
                    ),
                  });
                }}
              >
                <img
                  src={require("../../images/file-download-circle-green.svg")}
                  alt=""
                  style={
                    totalFiles > 0
                      ? {
                          width: "40px",
                          filter: "grayscale(0)",
                        }
                      : {
                          width: "40px",
                          filter: "grayscale(100%)  opacity(0.4)",
                        }
                  }
                />
              </button>
            );
          },
        },
        {
          title: "Campaign Name",
          field: "latestCampaign",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-word",
            minWidth: "180px",
          },
          headerStyle: {
            minWidth: "180px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {this.camelCase(rowData?.latestCampaign) || ""}
            </Clamp>
          ),
        },
        {
          title: "Salesperson",
          field: "salespersonName",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "170px",
          },
          headerStyle: {
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => {
            let name = "";
            try {
              if (rowData?.salespersonName?.length > 15) {
                name = `${rowData?.salespersonName?.slice(0, 15)}...`;
              } else {
                name = rowData?.salespersonName;
              }
            } catch (error) {}
            return <Clamp lines={2}>{name || ""}</Clamp>;
          },
        },
        {
          title: "Source",
          field: "source",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "170px",
          },
          headerStyle: {
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 5px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{putSource(rowData?.source) || ""}</Clamp>
          ),
        },
        {
          title: "SalesPerson Comments",
          field: "salescomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.salescomment?.[0] === "object"
                      ? rowData?.salescomment?.[0]?.comment
                      : rowData?.salescomment?.[0] ||
                        rowData?.salescomment ||
                        ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(
                      rowData?.salescomment
                    ),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "salescomment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        {
          title: "PreUW Comments",
          field: "comment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.comment?.[0] === "object"
                      ? rowData?.comment?.[0]?.comment
                      : rowData?.comment?.[0] || rowData?.comment || ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(rowData?.comment),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "comment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        {
          title: "SM Comments",
          field: "smcomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.smcomment?.[0] === "object"
                      ? rowData?.smcomment?.[0]?.comment
                      : rowData?.smcomment?.[0] || rowData?.smcomment || ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(
                      rowData?.smcomment
                    ),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "smcomment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        {
          title: "Deal Won/Lost",
          field: "dealFlagFilter",
          filtering: true,
          filterPlaceholder: "Filter",
          render: (rowData) => {
            let key = rowData.domain || rowData.salesID;
            let isClosed = false,
              isLost = false;
            if (key in dealMap) {
              isClosed = dealMap[key] === "true" ? true : false;
              isLost = dealMap[key] === "false" ? true : false;
            } else {
              isClosed = rowData.dealFlag === "closed" ? true : false;
              isLost = rowData.dealFlag === "lost" ? true : false;
            }
            let confirm = false;
            if (confirmDealMap?.[key] === "true") confirm = true;
            else if (rowData.dealConfirmed === "true") confirm = true;
            return (
              <DealClosedLostButtons
                recordDetails={rowData.recordDetails}
                isClosed={isClosed}
                isLost={isLost}
                updateDealMap={updateDealMap}
                disabled={confirm}
              />
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "150px",
          },
          headerStyle: {
            padding: "6px 5px 6px 20px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 15px",
          },
        },
        {
          title: "Confirm Deal",
          field: "dealConfirmed",
          filtering: false,
          render: (rowData) => {
            let key = rowData.domain || rowData.salesID;
            let isClosed = false,
              isLost = false;
            if (key in dealMap) {
              isClosed = dealMap[key] === "true" ? true : false;
              isLost = dealMap[key] === "false" ? true : false;
            } else {
              isClosed = rowData.dealFlag === "closed" ? true : false;
              isLost = rowData.dealFlag === "lost" ? true : false;
            }
            let confirm = false;
            if (confirmDealMap?.[key] === "true") confirm = true;
            else if (rowData.dealConfirmed === "true") confirm = true;

            return (
              <ConfirmDealButton
                updatePopUpData={this.updatePopUpData}
                rowData={rowData}
                isClosed={isClosed}
                isLost={isLost}
                updateConfirmDealMap={updateConfirmDealMap}
                disabled={confirm}
                handleDealConfirm={handleDealConfirm}
                tabName={tabName}
              />
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "150px",
          },
          headerStyle: {
            padding: "6px 5px 6px 20px",
          },
        },
      ],
      tab3: [
        {
          title: "Upload Date",
          field: "createdDate",
          filterPlaceholder: "Filter",
          customSort: (a, b) => this.dateSort(a, b, "createdDate"),
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            wordBreak: "break-all",
            padding: "3px 0",
            minWidth: "6rem",
          },
          headerStyle: {
            padding: "3px 0px 3px 4px",
            minWidth: "6rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
          render: (rowData) => <Clamp lines={1}>{rowData.createdDate}</Clamp>,
        },
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          filterPlaceholder: "Filter",
          defaultSort: "desc",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          customSort: (a, b) => this.dateSort(a, b, "visitDate"),
          render: (rowData) => {
            if (rowData.visitDate) {
              let date = rowData.visitDate.split("-")[0];
              let time = rowData.visitDate.split("-")[1];
              return (
                <span>
                  {date} {"&"}
                  {time}
                </span>
              );
            } else {
              return "-";
            }
          },
          cellStyle: {
            wordBreak: "break-all",
            padding: "3px 0",
            minWidth: "10rem",
          },
          headerStyle: {
            padding: "3px 0px 3px 4px",
            minWidth: "10rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          filterPlaceholder: "Filter",

          cellStyle: {
            padding: "6px 5px 6px 10px",
            minWidth: "12rem",
            wordBreak: "break-word",
          },
          headerStyle: {
            padding: "3px 0px 3px 10px",
            minWidth: "12rem",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 4px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              <span onClick={() => this.onClickCompanyName(rowData)}>
                <Link to={"/Quote"}>{this.camelCase(rowData.companyName)}</Link>
              </span>
            </Clamp>
          ),
        },
        {
          title: "Payroll",
          field: "total_payroll",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          render: (rowData) => numeral(rowData.total_payroll).format("$0,0"),
          cellStyle: {
            padding: "6px 10px 6px 10px",
            minWidth: "8rem",
            wordBreak: "break-all",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "7rem",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 0px",
          },
        },
        {
          title: "State",
          field: "state",

          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "6rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "6rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "City",
          field: "city",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => this.camelCase(rowData.city),
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          filterCellStyle: {
            padding: "2px 7px 2px 1px",
          },
        },
        {
          title: "# Emp",
          field: "total_emp",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 0",
            paddingLeft: "20px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            whiteSpace: "nowrap",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 15px",
          },
        },
        {
          title: "Status",
          field: "step",
          filterPlaceholder: "Filter",

          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px", minWidth: "8rem" };
            if (rowData.step === "Submitted") {
              style.background = "#5ADA38";
            } else if (rowData.step === "Proposal Generated") {
              style.backgroundColor = "#5ADA38";
            } else if (rowData.step === "UW Portal") {
              style.backgroundColor = "#ffd737ac";
            } else if (rowData.step === "Approved") {
              style.backgroundColor = "#87FA4D";
            } else if (rowData.step === "Rejected") {
              style.backgroundColor = "#fa6d3e";
            } else if (rowData.step === "Quote Generated") {
              style.backgroundColor = "#87FA20";
            } else if (rowData.step === "UW Questions") {
              style.backgroundColor = "#FFD737";
            } else if (Number(rowData.visitCount) > 0) {
              style.backgroundColor = "#FFD737";
            } else {
              style.backgroundColor = "#FFF";
            }
            return style;
          },
          headerStyle: {
            padding: "6px 0 6px 13px",
            minWidth: "8rem",
          },
          filterCellStyle: {
            padding: "2px 5px 2px 5px",
          },
        },
        {
          title: "Comments",
          field: "admincomment_download",
          filtering: true,
          filterPlaceholder: "Filter",
          render: (rowData) => {
            const callbackFunction = (dataToSave, rowData) => {
              this.updateAdminComment(dataToSave, rowData);
            };
            return (
              <div className="d-flex">
                <TextField
                  key={`${rowData?.salesID || rowData?.domain}_comment`}
                  value={
                    typeof adminHistoryComment?.[rowData?.salesID]?.[0] ===
                      "object" ||
                    typeof adminHistoryComment?.[rowData?.domain]?.[0] ===
                      "object"
                      ? adminHistoryComment?.[rowData?.salesID]?.[0]?.comment ||
                        adminHistoryComment?.[rowData?.domain]?.[0]?.comment
                      : adminHistoryComment?.[rowData?.salesID]?.[0] ||
                        adminHistoryComment?.[rowData?.domain]?.[0]
                  }
                  callback={callbackFunction}
                  UpdateCommentsHistoryState={updateHistoryComments}
                  rowData={rowData}
                  rowDataID={rowData?.salesID || rowData?.domain}
                  rowDataName={rowData?.salesID ? "id" : "domain"}
                  rowDataSortKeyList={rowData?.sortKeyList}
                  rowDataComments={"admincomment"}
                  comment={
                    adminHistoryComment?.[rowData?.salesID] ||
                    adminHistoryComment?.[rowData?.domain]
                  }
                  // handleAgentCommentHistory={this.handleAgentCommentHistory}
                />
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleAdminCommentsButtonClick(
                          adminHistoryComment?.[rowData?.salesID],
                          rowData?.createdDate,
                          rowData?.salesID
                        )
                      // console.log("data", adminHistoryComment?.[rowData?.salesID],"soujuuu")
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            minWidth: "230px",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 10px",
          },
        },
        {
          title: "Visit Count",
          field: "visitCount",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 0 6px 23px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0px 6px 15px",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 10px",
          },
        },
        {
          title: (
            <img
              src={require("../../images/file-download-circle-green.svg")}
              alt=""
              style={{
                width: "40px",
                // filter: "grayscale(100%)  opacity(0.4)",
              }}
            />
          ),
          disableSortBy: true,
          cellStyle: {
            minWidth: "30px",
            width: "30px",
            maxWidth: "30px",
            padding: "0 0",
          },
          headerStyle: {
            paddingLeft: "15px",
          },
          render: (rowData) => {
            let title = "";

            if (
              domain_messageid_files?.[rowData.domain]?.dashboardFiles?.length
            ) {
              for (let file of domain_messageid_files?.[rowData.domain]
                ?.dashboardFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }

            return (
              <button
                className="btn pt-0"
                type="button"
                title={parse(title)}
                data-html="true"
                onClick={() => {
                  this.setState({
                    showModel: true,
                    modalTitle: <h1>{"Download"}</h1>,
                    modalContent: (
                      <>
                        <DownloadDashboardFiles
                          dashboardFiles={
                            domain_messageid_files?.[rowData.domain]
                              ?.dashboardFiles || []
                          }
                          domainName={rowData.domain}
                          updateDomainMessageidFiles={
                            this.updateDomainMessageidFiles
                          }
                        />
                      </>
                    ),
                  });
                }}
              >
                <img
                  src={require("../../images/file-download-circle-green.svg")}
                  alt=""
                  style={
                    domain_messageid_files?.[rowData.domain]?.dashboardFiles
                      ?.length
                      ? {
                          width: "40px",
                          filter: "grayscale(0)",
                        }
                      : {
                          width: "40px",
                          filter: "grayscale(100%)  opacity(0.4)",
                        }
                  }
                />
              </button>
            );
          },
        },
        {
          title: "Campaign Name",
          field: "latestCampaign",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-word",
            minWidth: "180px",
          },
          headerStyle: {
            minWidth: "180px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {this.camelCase(rowData?.latestCampaign) || ""}
            </Clamp>
          ),
        },
        {
          title: "Salesperson",
          field: "salespersonName",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "170px",
          },
          headerStyle: {
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => {
            let name = "";
            try {
              if (rowData?.salespersonName?.length > 15) {
                name = `${rowData?.salespersonName.slice(0, 15)}...`;
              } else {
                name = rowData?.salespersonName;
              }
            } catch (error) {}
            return <Clamp lines={2}>{name || ""}</Clamp>;
          },
        },
        {
          title: "Quote ID",
          field: "customInput", // You can define this field in your data structure if needed
          render: (rowData) => (
            <input
              type="text"
              // value={rowData.customInput || ""}
              onBlur={(e) => this.salesIDCheck(e, rowData)}
              placeholder="Enter value"
              style={{
                width: "100%",
                padding: "5px",
                borderRadius: "3px",
                border: "1px solid #ccc",
              }}
            />
          ),
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "150px",
          },
          headerStyle: {
            padding: "6px 5px 6px 20px",
          },
        },
        {
          title: "SalesPerson Comments",
          field: "salescomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.salescomment?.[0] === "object"
                      ? rowData?.salescomment?.[0]?.comment
                      : rowData?.salescomment?.[0] ||
                        rowData?.salescomment ||
                        ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(
                      rowData?.salescomment
                    ),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "salescomment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        {
          title: "SM Comments",
          field: "smcomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.smcomment?.[0] === "object"
                      ? rowData?.smcomment?.[0]?.comment
                      : rowData?.smcomment?.[0] || rowData?.smcomment || ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(
                      rowData?.smcomment
                    ),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "smcomment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        {
          title: "Deal Won/Lost",
          field: "dealFlagFilter",
          filtering: true,
          filterPlaceholder: "Filter",
          render: (rowData) => {
            let key = rowData.domain || rowData.salesID;
            let isClosed = false,
              isLost = false;
            if (key in dealMap) {
              isClosed = dealMap[key] === "true" ? true : false;
              isLost = dealMap[key] === "false" ? true : false;
            } else {
              isClosed = rowData.dealFlag === "closed" ? true : false;
              isLost = rowData.dealFlag === "lost" ? true : false;
            }
            let confirm = false;
            if (confirmDealMap?.[key] === "true") confirm = true;
            else if (rowData.dealConfirmed === "true") confirm = true;

            return (
              <DealClosedLostButtons
                recordDetails={rowData.recordDetails}
                isClosed={isClosed}
                isLost={isLost}
                updateDealMap={updateDealMap}
                disabled={confirm}
              />
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "150px",
          },
          headerStyle: {
            padding: "6px 5px 6px 20px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 15px",
          },
        },
        {
          title: "Confirm Deal",
          field: "dealConfirmed",
          filtering: false,
          render: (rowData) => {
            let key = rowData.domain || rowData.salesID;
            let isClosed = false,
              isLost = false;
            if (key in dealMap) {
              isClosed = dealMap[key] === "true" ? true : false;
              isLost = dealMap[key] === "false" ? true : false;
            } else {
              isClosed = rowData.dealFlag === "closed" ? true : false;
              isLost = rowData.dealFlag === "lost" ? true : false;
            }
            let confirm = false;
            if (confirmDealMap?.[key] === "true") confirm = true;
            else if (rowData.dealConfirmed === "true") confirm = true;

            return (
              <ConfirmDealButton
                updatePopUpData={this.updatePopUpData}
                rowData={rowData}
                isClosed={isClosed}
                isLost={isLost}
                updateConfirmDealMap={updateConfirmDealMap}
                disabled={confirm}
                handleDealConfirm={handleDealConfirm}
                tabName={tabName}
              />
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "150px",
          },
          headerStyle: {
            padding: "6px 5px 6px 20px",
          },
        },
      ],
      tab4: [
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          filterPlaceholder: "Filter",
          defaultSort: "desc",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          customSort: (a, b) => this.dateSort(a, b, "visitDate"),
          render: (rowData) => {
            if (rowData.visitDate) {
              let date = rowData.visitDate.split("-")[0];
              let time = rowData.visitDate.split("-")[1];
              return (
                <span>
                  {date} {"&"}
                  {time}
                </span>
              );
            } else {
              return "-";
            }
          },
          cellStyle: {
            wordBreak: "break-all",
            padding: "3px 0",
            minWidth: "10rem",
          },
          headerStyle: {
            padding: "3px 0px 3px 4px",
            minWidth: "10rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          filterPlaceholder: "Filter",

          cellStyle: {
            padding: "6px 5px 6px 10px",
            minWidth: "12rem",
            wordBreak: "break-word",
          },
          headerStyle: {
            padding: "3px 0px 3px 12px",
            minWidth: "12rem",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 4px",
          },
          render: (rowData) => {
            if (rowData.tab === "tab3") {
              return (
                <Clamp lines={2}>
                  <span onClick={() => this.onClickCompanyName(rowData)}>
                    <Link to={"/Quote"}>
                      {this.camelCase(rowData.companyName)}
                    </Link>
                  </span>
                </Clamp>
              );
            } else if (rowData.tab === "tab1") {
              let random_user = Object.keys(rowData?.email_specific || {})[0];
              return (
                <Clamp lines={2}>
                  <span
                    onClick={() => {
                      sessionStorage.setItem("hideButtons", "true");
                      sessionStorage.setItem("showAllFields", true);
                      sessionStorage.setItem("salespersonMailCampaign", true);
                    }}
                  >
                    <Link to={`/Quote/?user=${random_user}&viewButton=true`}>
                      {this.camelCase(rowData?.companyName || "-")}
                    </Link>
                  </span>
                </Clamp>
              );
            }
            return (
              <Clamp lines={2}>
                <span>{this.camelCase(rowData.companyName)}</span>
              </Clamp>
            );
          },
        },
        {
          title: "Payroll",
          field: "total_payroll",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          render: (rowData) =>
            numeral(rowData.total_payroll || rowData.payroll).format("$0,0"),
          cellStyle: {
            padding: "6px 10px 6px 10px",
            minWidth: "8rem",
            wordBreak: "break-all",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "7rem",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 0px",
          },
        },
        {
          title: "State",
          field: "state",

          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "City",
          field: "city",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => this.camelCase(rowData.city),
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          filterCellStyle: {
            padding: "2px 7px 2px 1px",
          },
        },
        {
          title: "# Emp",
          field: "total_emp",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 0",
            paddingLeft: "20px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            whiteSpace: "nowrap",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 15px",
          },
        },
        {
          title: "Status",
          field: "step",
          filterPlaceholder: "Filter",

          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px", minWidth: "8rem" };
            if (rowData.step === "Submitted") {
              style.background = "#5ADA38";
            } else if (rowData.step === "Proposal Generated") {
              style.backgroundColor = "#5ADA38";
            } else if (rowData.step === "UW Portal") {
              style.backgroundColor = "#ffd737ac";
            } else if (rowData.step === "Approved") {
              style.backgroundColor = "#87FA4D";
            } else if (rowData.step === "Rejected") {
              style.backgroundColor = "#fa6d3e";
            } else if (rowData.step === "Quote Generated") {
              style.backgroundColor = "#87FA20";
            } else if (rowData.step === "UW Questions") {
              style.backgroundColor = "#FFD737";
            } else if (Number(rowData.visitCount) > 0) {
              style.backgroundColor = "#FFD737";
            } else {
              style.backgroundColor = "#FFF";
            }
            return style;
          },
          headerStyle: {
            padding: "6px 0 6px 13px",
            minWidth: "8rem",
          },
          filterCellStyle: {
            padding: "2px 5px 2px 5px",
          },
        },
        {
          title: "Comments",
          field: "admincomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            const callbackFunction = (dataToSave, rowData) => {
              if (dataToSave)
                this.updateAdminWonLossComment(dataToSave, rowData);
            };
            // console.log(rowData.salesID, rowData.domain)
            return (
              <div className="d-flex">
                <TextField
                  key={`${rowData?.salesID || rowData?.domain}_comment`}
                  value={
                    typeof wonListCommentsHistory?.[rowData?.salesID]?.[0] ===
                      "object" ||
                    typeof wonListCommentsHistory?.[rowData?.domain]?.[0] ===
                      "object"
                      ? wonListCommentsHistory?.[rowData?.salesID]?.[0]
                          ?.comment ||
                        wonListCommentsHistory?.[rowData?.domain]?.[0]?.comment
                      : wonListCommentsHistory?.[rowData?.salesID]?.[0] ||
                        wonListCommentsHistory?.[rowData?.domain]?.[0]
                  }
                  callback={callbackFunction}
                  UpdateCommentsHistoryState={
                    this.props.updatewonAdminHistoryComments
                  }
                  rowData={rowData}
                  rowDataID={rowData?.salesID || rowData?.domain}
                  rowDataName={rowData?.domain ? "domain" : "id"}
                  rowDataSortKeyList={rowData?.sortKeyList}
                  rowDataComments={"admincomment"}
                  comment={
                    wonListCommentsHistory?.[rowData?.salesID] ||
                    wonListCommentsHistory?.[rowData?.domain]
                  }
                  // handleAgentCommentHistory={this.handleAgentCommentHistory}
                />
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleAdminCommentsButtonClick(
                          wonListCommentsHistory?.[rowData?.salesID] ||
                            wonListCommentsHistory?.[rowData?.domain],
                          rowData?.createdDate || rowData?.visitDate
                        )
                      // console.log("data", adminHistoryComment?.[rowData?.salesID])
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            minWidth: "230px",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 10px",
          },
        },
        {
          title: "Visit Count",
          field: "visitCount",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 0 6px 23px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0px 6px 15px",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 10px",
          },
        },
        {
          title: (
            <img
              src={require("../../images/file-download-circle-green.svg")}
              alt=""
              style={{
                width: "40px",
                // filter: "grayscale(100%)  opacity(0.4)",
              }}
            />
          ),
          disableSortBy: true,
          cellStyle: {
            minWidth: "30px",
            width: "30px",
            maxWidth: "30px",
            padding: "0 0",
          },
          headerStyle: {
            paddingLeft: "15px",
          },
          render: (rowData) => {
            let title = "";

            if (
              domain_messageid_files?.[rowData.domain]?.dashboardFiles?.length
            ) {
              for (let file of domain_messageid_files?.[rowData.domain]
                ?.dashboardFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }

            return (
              <button
                className="btn pt-0"
                type="button"
                title={parse(title)}
                data-html="true"
                onClick={() => {
                  this.setState({
                    showModel: true,
                    modalTitle: <h1>{"Download"}</h1>,
                    modalContent: (
                      <>
                        <DownloadDashboardFiles
                          dashboardFiles={
                            domain_messageid_files?.[rowData.domain]
                              ?.dashboardFiles || []
                          }
                          domainName={rowData.domain}
                          updateDomainMessageidFiles={
                            this.updateDomainMessageidFiles
                          }
                        />
                      </>
                    ),
                  });
                }}
              >
                <img
                  src={require("../../images/file-download-circle-green.svg")}
                  alt=""
                  style={
                    domain_messageid_files?.[rowData.domain]?.dashboardFiles
                      ?.length
                      ? {
                          width: "40px",
                          filter: "grayscale(0)",
                        }
                      : {
                          width: "40px",
                          filter: "grayscale(100%)  opacity(0.4)",
                        }
                  }
                />
              </button>
            );
          },
        },
        {
          title: "Campaign Name",
          field: "latestCampaign",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-word",
            minWidth: "180px",
          },
          headerStyle: {
            minWidth: "180px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {this.camelCase(rowData?.latestCampaign) || ""}
            </Clamp>
          ),
        },
        {
          title: "Salesperson",
          field: "salespersonName",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "170px",
          },
          headerStyle: {
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => {
            let name = "";
            try {
              if (rowData?.salespersonName?.length > 15) {
                name = `${rowData?.salespersonName.slice(0, 15)}...`;
              } else {
                name = rowData?.salespersonName;
              }
            } catch (error) {}
            return <Clamp lines={2}>{name || ""}</Clamp>;
          },
        },
        {
          title: "Source",
          field: "source",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "170px",
          },
          headerStyle: {
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 5px",
          },
          render: (rowData) => {
            if (rowData.tab === "tab3") {
              return <Clamp lines={1}>Salesforce</Clamp>;
            }
            return <Clamp lines={1}>{putSource(rowData?.source) || ""}</Clamp>;
          },
        },
        {
          title: "SalesPerson Comments",
          field: "salescomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.salescomment?.[0] === "object"
                      ? rowData?.salescomment?.[0]?.comment
                      : rowData?.salescomment?.[0] ||
                        rowData?.salescomment ||
                        ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(
                      rowData?.salescomment
                    ),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "salescomment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        {
          title: "PreUW Comments",
          field: "comment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.comment?.[0] === "object"
                      ? rowData?.comment?.[0]?.comment
                      : rowData?.comment?.[0] || rowData?.comment || ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(rowData?.comment),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "comment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        {
          title: "SM Comments",
          field: "smcomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.smcomment?.[0] === "object"
                      ? rowData?.smcomment?.[0]?.comment
                      : rowData?.smcomment?.[0] || rowData?.smcomment || ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(
                      rowData?.smcomment
                    ),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "smcomment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
      ],
      tab5: [
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          filterPlaceholder: "Filter",
          defaultSort: "desc",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          customSort: (a, b) => this.dateSort(a, b, "visitDate"),
          render: (rowData) => {
            if (rowData.visitDate) {
              let date = rowData.visitDate.split("-")[0];
              let time = rowData.visitDate.split("-")[1];
              return (
                <span>
                  {date} {"&"}
                  {time}
                </span>
              );
            } else {
              return "-";
            }
          },
          cellStyle: {
            wordBreak: "break-all",
            padding: "3px 0",
            minWidth: "10rem",
          },
          headerStyle: {
            padding: "3px 0px 3px 4px",
            minWidth: "10rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          filterPlaceholder: "Filter",

          cellStyle: {
            padding: "6px 5px 6px 10px",
            minWidth: "12rem",
            wordBreak: "break-word",
          },
          headerStyle: {
            padding: "3px 0px 3px 12px",
            minWidth: "12rem",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 4px",
          },
          render: (rowData) => {
            if (rowData.tab === "tab3") {
              return (
                <Clamp lines={2}>
                  <span onClick={() => this.onClickCompanyName(rowData)}>
                    <Link to={"/Quote"}>
                      {this.camelCase(rowData.companyName)}
                    </Link>
                  </span>
                </Clamp>
              );
            } else if (rowData.tab === "tab1") {
              let random_user = Object.keys(rowData?.email_specific || {})[0];
              return (
                <Clamp lines={2}>
                  <span
                    onClick={() => {
                      sessionStorage.setItem("hideButtons", "true");
                      sessionStorage.setItem("showAllFields", true);
                      sessionStorage.setItem("salespersonMailCampaign", true);
                    }}
                  >
                    <Link to={`/Quote/?user=${random_user}&viewButton=true`}>
                      {this.camelCase(rowData?.companyName || "-")}
                    </Link>
                  </span>
                </Clamp>
              );
            }
            return (
              <Clamp lines={2}>
                <span>{this.camelCase(rowData.companyName)}</span>
              </Clamp>
            );
          },
        },
        {
          title: "Payroll",
          field: "total_payroll",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          render: (rowData) =>
            numeral(rowData.total_payroll || rowData.payroll).format("$0,0"),
          cellStyle: {
            padding: "6px 10px 6px 10px",
            minWidth: "8rem",
            wordBreak: "break-all",
            textAlign: "right",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "7rem",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 0px",
          },
        },
        {
          title: "State",
          field: "state",

          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 0px",
          },
        },
        {
          title: "City",
          field: "city",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => this.camelCase(rowData.city),
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "8rem",
          },
          filterCellStyle: {
            padding: "2px 7px 2px 1px",
          },
        },
        {
          title: "# Emp",
          field: "total_emp",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 0",
            paddingLeft: "20px",
            minWidth: "125px",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            whiteSpace: "nowrap",
            minWidth: "125px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 15px",
          },
        },
        {
          title: "Status",
          field: "step",
          filterPlaceholder: "Filter",

          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px", minWidth: "8rem" };
            if (rowData.step === "Submitted") {
              style.background = "#5ADA38";
            } else if (rowData.step === "Proposal Generated") {
              style.backgroundColor = "#5ADA38";
            } else if (rowData.step === "UW Portal") {
              style.backgroundColor = "#ffd737ac";
            } else if (rowData.step === "Approved") {
              style.backgroundColor = "#87FA4D";
            } else if (rowData.step === "Rejected") {
              style.backgroundColor = "#fa6d3e";
            } else if (rowData.step === "Quote Generated") {
              style.backgroundColor = "#87FA20";
            } else if (rowData.step === "UW Questions") {
              style.backgroundColor = "#FFD737";
            } else if (Number(rowData.visitCount) > 0) {
              style.backgroundColor = "#FFD737";
            } else {
              style.backgroundColor = "#FFF";
            }
            return style;
          },
          headerStyle: {
            padding: "6px 0 6px 13px",
            minWidth: "8rem",
          },
          filterCellStyle: {
            padding: "2px 5px 2px 5px",
          },
        },
        {
          title: "Comments",
          field: "admincomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            const callbackFunction = (dataToSave, rowData) => {
              if (dataToSave)
                this.updateAdminWonLossComment(dataToSave, rowData);
            };
            return (
              <div className="d-flex">
                <TextField
                  key={`${rowData?.salesID || rowData?.domain}_comment`}
                  value={
                    typeof lostListCommentsHistory?.[rowData?.salesID]?.[0] ===
                      "object" ||
                    typeof lostListCommentsHistory?.[rowData?.domain]?.[0] ===
                      "object"
                      ? lostListCommentsHistory?.[rowData?.salesID]?.[0]
                          ?.comment ||
                        lostListCommentsHistory?.[rowData?.domain]?.[0]?.comment
                      : lostListCommentsHistory?.[rowData?.salesID]?.[0] ||
                        lostListCommentsHistory?.[rowData?.domain]?.[0]
                  }
                  callback={callbackFunction}
                  UpdateCommentsHistoryState={
                    this.props.updateLostAdminHistoryComments
                  }
                  rowData={rowData}
                  rowDataID={rowData?.salesID || rowData?.domain}
                  rowDataName={rowData?.domain ? "domain" : "id"}
                  rowDataSortKeyList={rowData?.sortKeyList}
                  rowDataComments={"admincomment"}
                  comment={
                    lostListCommentsHistory?.[rowData?.salesID] ||
                    lostListCommentsHistory?.[rowData?.domain]
                  }
                  // handleAgentCommentHistory={this.handleAgentCommentHistory}
                />
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleAdminCommentsButtonClick(
                          lostListCommentsHistory?.[rowData?.salesID] ||
                            lostListCommentsHistory?.[rowData?.domain],
                          rowData?.createdDate || rowData?.visitDate
                        )
                      // console.log("data", adminHistoryComment?.[rowData?.salesID])
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            padding: "6px 0 6px 18px",
            minWidth: "230px",
          },
          filterCellStyle: {
            padding: "2px 0px 2px 10px",
          },
        },
        {
          title: "Visit Count",
          field: "visitCount",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 0 6px 23px",
            minWidth: "5rem",
          },
          headerStyle: {
            padding: "6px 0px 6px 15px",
            minWidth: "5rem",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 10px",
          },
        },
        {
          title: (
            <img
              src={require("../../images/file-download-circle-green.svg")}
              alt=""
              style={{
                width: "40px",
                // filter: "grayscale(100%)  opacity(0.4)",
              }}
            />
          ),
          disableSortBy: true,
          cellStyle: {
            minWidth: "30px",
            width: "30px",
            maxWidth: "30px",
            padding: "0 0",
          },
          headerStyle: {
            paddingLeft: "15px",
          },
          render: (rowData) => {
            let title = "";

            if (
              domain_messageid_files?.[rowData.domain]?.dashboardFiles?.length
            ) {
              for (let file of domain_messageid_files?.[rowData.domain]
                ?.dashboardFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }

            return (
              <button
                className="btn pt-0"
                type="button"
                title={parse(title)}
                data-html="true"
                onClick={() => {
                  this.setState({
                    showModel: true,
                    modalTitle: <h1>{"Download"}</h1>,
                    modalContent: (
                      <>
                        <DownloadDashboardFiles
                          dashboardFiles={
                            domain_messageid_files?.[rowData.domain]
                              ?.dashboardFiles || []
                          }
                          domainName={rowData.domain}
                          updateDomainMessageidFiles={
                            this.updateDomainMessageidFiles
                          }
                        />
                      </>
                    ),
                  });
                }}
              >
                <img
                  src={require("../../images/file-download-circle-green.svg")}
                  alt=""
                  style={
                    domain_messageid_files?.[rowData.domain]?.dashboardFiles
                      ?.length
                      ? {
                          width: "40px",
                          filter: "grayscale(0)",
                        }
                      : {
                          width: "40px",
                          filter: "grayscale(100%)  opacity(0.4)",
                        }
                  }
                />
              </button>
            );
          },
        },
        {
          title: "Campaign Name",
          field: "latestCampaign",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-word",
            minWidth: "180px",
          },
          headerStyle: {
            minWidth: "180px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {this.camelCase(rowData?.latestCampaign) || ""}
            </Clamp>
          ),
        },
        {
          title: "Salesperson",
          field: "salespersonName",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "170px",
          },
          headerStyle: {
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 3px",
          },
          render: (rowData) => {
            let name = "";
            try {
              if (rowData?.salespersonName?.length > 15) {
                name = `${rowData?.salespersonName.slice(0, 15)}...`;
              } else {
                name = rowData?.salespersonName;
              }
            } catch (error) {}
            return <Clamp lines={2}>{name || ""}</Clamp>;
          },
        },
        {
          title: "Source",
          field: "source",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "170px",
          },
          headerStyle: {
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 18px 2px 5px",
          },
          render: (rowData) => {
            if (rowData.tab === "tab3") {
              return <Clamp lines={1}>Salesforce</Clamp>;
            }
            return <Clamp lines={1}>{putSource(rowData?.source) || ""}</Clamp>;
          },
        },
        {
          title: "SalesPerson Comments",
          field: "salescomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.salescomment?.[0] === "object"
                      ? rowData?.salescomment?.[0]?.comment
                      : rowData?.salescomment?.[0] ||
                        rowData?.salescomment ||
                        ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(
                      rowData?.salescomment
                    ),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "salescomment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        {
          title: "PreUW Comments",
          field: "comment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.comment?.[0] === "object"
                      ? rowData?.comment?.[0]?.comment
                      : rowData?.comment?.[0] || rowData?.comment || ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(rowData?.comment),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "comment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
        {
          title: "SM Comments",
          field: "smcomment_download",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <div className="d-flex">
                <textarea
                  defaultValue={
                    typeof rowData?.smcomment?.[0] === "object"
                      ? rowData?.smcomment?.[0]?.comment
                      : rowData?.smcomment?.[0] || rowData?.smcomment || ""
                  }
                  disabled
                  rows="1"
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    backgroundColor: this.setCommentBoxColor(
                      rowData?.smcomment
                    ),
                  }}
                ></textarea>
                <Tooltip title="Click to view comment history" arrow>
                  <button
                    className="comment-btn"
                    onClick={
                      () =>
                        this.handleCommentHistory(
                          [rowData],
                          `${rowData?.salesID}`,
                          "smcomment"
                        )
                      // console.log([rowData], `${rowData.salesID}`)
                    }
                  >
                    ...
                  </button>
                </Tooltip>
              </div>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "230px",
          },
          headerStyle: {
            minWidth: "230px",
            paddingLeft: "10px",
          },
          filterCellStyle: {
            padding: "2px 15px 2px 5px",
          },
        },
      ],
    };

    if (uploadDateFlag) {
      let uploadDate = {
        title: "Upload Date",
        field: "uploadedTimeStamp",
        filterPlaceholder: "Filter",
        customSort: (a, b) => this.dateSort(a, b, "uploadedTimeStamp"),
        cellStyle: {
          wordBreak: "break-all",
          padding: "6px 0",
          minWidth: "70px",
        },
        headerStyle: {
          padding: "6px 0",
        },
        render: (rowData) => (
          <Clamp lines={1}>{rowData.uploadedTimeStamp}</Clamp>
        ),
      };
      columns.tab1.unshift(uploadDate);
      columns.tab2.unshift(uploadDate);
    }

    return (
      <>
        <div className="d-flex">
          {(tabName === "tab1" || tabName === "tab2" || tabName === "tab3") && (
            <LoadingOverlay active={this.state.confirmAllLoading}>
              <button
                className="btn btn-primary btnConfirmAll"
                onClick={() => {
                  this.handleConfirmAll(tabName);
                }}
              >
                Confirm All
              </button>
            </LoadingOverlay>
          )}
          {this.state.dataLoading ? (
            <div
              style={{ margin: "10px" }}
              class="spinner-border text-primary"
              role="status"
            >
              <span class="sr-only"></span>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div style={{ position: "relative" }}>
          {scrollLeft && (
            <button className="ChevronLeft" onClick={this.scrollLeft}>
              &lt;
            </button>
          )}
          {scrollRight && (
            <button className="ChevronRight" onClick={this.scrollRight}>
              &gt;
            </button>
          )}

          <MaterialTable
            isLoading={
              tabName === "tab1" || tabName === "tab2" || tabName === "tab3"
                ? isLoading
                : this.props.isLoading
            }
            icons={tableIcons}
            data={
              tabName === "tab1" ? rowDataList[sourceSelected] : rowDataList
            }
            columns={columns[tabName]}
            onSelectionChange={(rows) => this.handleSelection(rows)}
            options={{
              selection:
                tabName === "tab4" || tabName === "tab5" ? false : true,
              selectionProps: (rowData) => {
                let key = rowData.domain || rowData.salesID;
                return {
                  disabled:
                    rowData.dealConfirmed === "true" ||
                    (dealMap[key] !== "true" &&
                      dealMap[key] !== "false" &&
                      rowData.dealFlag !== "lost" &&
                      rowData.dealFlag !== "closed"),
                  color: "primary",
                };
              },
              stickyHeader: true,
              exportButton: true,
              doubleHorizontalScroll: true,
              filtering: true,
              filter: true,
              search: true,
              sorting: true,
              paginationPosition: "both",
              padding: "dense",
              showTitle: false,
              thirdSortClick: false,
              pageSize: 10,
              pageSizeOptions: [
                10,
                25,
                50,
                75,
                100,
                tabName === "tab1"
                  ? rowDataList?.[sourceSelected].length
                    ? rowDataList?.[sourceSelected].length
                    : 0
                  : rowDataList.length
                  ? rowDataList.length
                  : 0,
              ],
              headerStyle: {
                fontSize: ismobile ? this.fontfunciton() : "1rem",
                backgroundColor: "#003764",
                color: "#FFF",
                padding: "3px 15px 3px 0px",
                fontFamily: "Montserrat",
              },
              rowStyle: {
                fontSize: ismobile ? this.fontfunciton() : "0.95rem",
                color: "#000",
                fontFamily: "Montserrat",
              },
              searchFieldStyle: {
                fontSize: "0.95rem",
              },
              filterCellStyle: {
                padding: "4px 10px 4px 0px",
              },
            }}
            detailPanel={
              tabName === "tab2"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        return this.tab2DetailPanel(rowData);
                      },
                    },
                  ]
                : tabName === "tab1"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        return this.tab1DetailPanel(rowData);
                      },
                    },
                  ]
                : tabName === "tab3"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        return this.tab3DetailPanel(rowData);
                      },
                    },
                  ]
                : tabName === "tab4" || tabName === "tab5"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        if (rowData.tab === "tab1") {
                          return this.tab1DetailPanel(rowData);
                        } else if (rowData.tab === "tab2") {
                          return this.tab2DetailPanel(rowData);
                        } else if (rowData.tab === "tab3") {
                          return this.tab3DetailPanel(rowData);
                        }
                      },
                    },
                  ]
                : false
            }
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  labelRowsPerPage={
                    <div style={{ fontSize: 12 }}>{props.labelRowsPerPage}</div>
                  }
                  labelDisplayedRows={(row) => (
                    <div style={{ fontSize: 12 }}>
                      {props.labelDisplayedRows(row)}
                    </div>
                  )}
                  SelectProps={{
                    style: {
                      fontSize: 12,
                    },
                  }}
                />
              ),
            }}
          />
        </div>
      </>
    );
  };

  updateSalesDisplay = async () => {
    let _adminHistoryComment = {};
    let sessionData = await Auth.currentAuthenticatedUser();
    if (sessionData) {
      let requestBody = {
        first_time_loading: "true",
        lastEvaluatedKey: "",
      };
      requestBody.salesPersonEmail = sessionData.attributes.email;
      let rowDataList = [];
      let last_key = "";
      let indx = 0;
      do {
        requestBody = {
          ...requestBody,
          lastEvaluatedKey: last_key,
        };

        if (indx === 1) delete requestBody["first_time_loading"];

        await getSalesDashboardDataAdmin(requestBody)
          .then((res) => {
            let userDataList = [];
            let rowData;

            userDataList = res.data.salesforce;
            last_key = res.data.old_key || "";

            for (let userDataRow in userDataList) {
              let step;
              let userData = userDataList[userDataRow];

              if (userData.formStage) {
                switch (userData.formStage) {
                  case "two":
                    step = "UW Questions";
                    break;
                  case "three":
                    step = "Quote Generated";
                    break;
                  default:
                    break;
                }
              }

              let currProspectDetails = {
                companyProfile: userData.companyProfile,
                childrenLoc: userData.payrollData,
                emodStatesData: userData.emodStatesData,
                companyProfileAddQuestions: userData.companyProfileAddQuestions,
                uwQues: userData.uwQues,
                requestedPricing: userData.requestedPricing,
              };

              if (userData?.brokerDetails) {
                currProspectDetails.brokerDetails = userData.brokerDetails;
              }

              let total_emp = 0;
              let total_payroll = 0;

              if (userData.payrollData) {
                let childrenLoc = userData.payrollData;
                for (let locIndex in childrenLoc) {
                  let loc = childrenLoc[locIndex];
                  for (let ccIndex in loc.classCodesInfo) {
                    let cc = loc.classCodesInfo[ccIndex];
                    total_emp =
                      total_emp +
                      Number(cc?.ft?.value || 0) +
                      Number(cc?.pt?.value || 0);

                    total_payroll =
                      total_payroll +
                      (numeral(cc?.payroll?.value).value() || 0);
                  }
                }
              }

              rowData = {
                visitDate:
                  userData.updated_timestamp &&
                  moment(Number(userData.updated_timestamp))
                    .utcOffset("-0500")
                    .format("M/D/YYYY - hh:mm A"),
                phoneNumber:
                  userData.companyProfile && userData.companyProfile.phoneNumber
                    ? userData.companyProfile.phoneNumber.value
                    : "",
                effectiveDate:
                  userData.companyProfile &&
                  userData.companyProfile.effectiveDate &&
                  userData.companyProfile.effectiveDate.value
                    ? moment(
                        userData.companyProfile.effectiveDate.value
                      ).format("x")
                    : "",
                contactName:
                  userData.companyProfile && userData.companyProfile.firstName
                    ? userData.companyProfile.firstName.value
                    : "",
                companyName:
                  userData.companyProfile && userData.companyProfile.companyName
                    ? userData.companyProfile.companyName.value
                    : "",
                step: userData.isSubmitted ? "Submitted" : step,
                currProspectDetails,
                salesID: userData.id,
                formStage: userData.formStage,
                amtrustAccountDetails: userData.amtrustAccountDetails,
                salespersonName:
                  userData.salespersonNameAttr || userData.salespersonName,
                total_emp,
                state: userData?.companyProfile?.state1?.value || "-",
                city: userData?.companyProfile?.cityName1?.value || "-",
                quoteData: userData.quoteData,
                address: userData.address,
                total_payroll,
                createdDate: userData.created_timestamp
                  ? moment(userData.created_timestamp, "x").format("M/D/YYYY")
                  : "-",
                companyProfile: userData.companyProfile,
                sortKeyList: userData.sortKeyList,
                salescomment: userData?.comment
                  ? typeof userData?.comment === "string"
                    ? [
                        {
                          ...initialcomment,
                          comment: userData?.comment,
                          commentTimestamp: userData?.updated_timestamp,
                        },
                      ]
                    : userData?.comment
                  : [{ ...initialcomment }],
                smcomment: userData?.smcomment
                  ? typeof userData?.smcomment === "string"
                    ? [
                        {
                          ...initialcomment,
                          comment: userData?.smcomment,
                          commentTimestamp: userData?.updated_timestamp,
                        },
                      ]
                    : userData?.smcomment
                  : [{ ...initialcomment }],
                admincomment: userData?.admincomment
                  ? typeof userData?.admincomment === "string"
                    ? [
                        {
                          ...initialcomment,
                          comment: userData?.admincomment,
                          commentTimestamp: userData?.updated_timestamp,
                        },
                      ]
                    : userData?.admincomment
                  : [{ ...initialcomment }],
                preUwFiles: userData.preUwFiles || [],
                dashboardFiles: userData.dashboardFiles || [],
                fileListToStore: userData.fileListToStore || [],
                source: userData.source,
                dealFlag:
                  userData?.dealData?.status === "true"
                    ? "closed"
                    : userData?.dealData?.status === "false"
                    ? "lost"
                    : "",
                dealFlagFilter:
                  userData?.dealData?.status === "true"
                    ? "won"
                    : userData?.dealData?.status === "false"
                    ? "lost"
                    : "",
                dealConfirmed: userData?.dealConfirmed,
                recordDetails: {
                  salesId: userData.id,
                },
                tab: this.props.tabName,
              };

              createCommentForDownload(rowData, "comment");
              createCommentForDownload(rowData, "admincomment");
              createCommentForDownload(rowData, "salescomment");
              createCommentForDownload(rowData, "smcomment");
              // console.log(rowData,"soujuuu")

              _adminHistoryComment[rowData.salesID] = rowData?.admincomment
                ? typeof rowData.admincomment === "string"
                  ? [
                      {
                        ...initialcomment,
                        comment: rowData.admincomment,
                        commentTimestamp: rowData?.visitDate,
                      },
                    ]
                  : rowData.admincomment
                : [{ ...initialcomment }];

              rowDataList.push(rowData);
            }

            this.setState((prevState) => {
              let adminHistoryComment = JSON.parse(
                JSON.stringify(prevState.adminHistoryComment)
              );

              return {
                adminHistoryComment: {
                  ..._adminHistoryComment,
                  ...adminHistoryComment,
                },
              };
            });

            if (indx < 1) this.props.setRowDataList("tab3", rowDataList, false);
            else this.props.setRowDataList("tab3", rowDataList, true);
          })
          .catch((error) => {
            console.log("error", error);
          })
          .finally(() => {
            this.setState({ isLoading: false });
          });
        indx += 1;
      } while (indx === 1 || last_key);
      if (!last_key) {
        this.setState({ dataLoading: false });
      }
    }
  };

  updateEligibilityDisplay = async () => {
    await axios
      .get(awsUrl2 + "/api/getNonEligibleData")
      .then((res) => {
        let data = res.data;
        this.setState({ rowDataList: data });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updatePaygoData = () => {
    axios
      .post(awsUrl2 + "/api/peoCampaignData/getAll", {})
      .then((res) => {
        let rowData;
        let rowDataList = [];

        let data = res.data;

        let payroll = 0,
          emod = 1;

        for (let row in data) {
          if (
            data[row].emodStatesData &&
            data[row].emodStatesData[Object.keys(data[row].emodStatesData)[0]]
              .rate1
          ) {
            emod =
              data[row].emodStatesData[Object.keys(data[row].emodStatesData)[0]]
                .rate1.value;
          }

          if (data[row].payrollData) {
            for (var state in data[row].payrollData) {
              for (var pay in data[row].payrollData[state].classCodesInfo) {
                var pyrll = numeral(
                  data[row].payrollData[state].classCodesInfo[pay].payroll.value
                ).value();

                payroll += parseInt(pyrll);
              }
            }
          }
          data[row].payroll = payroll;
          data[row].emod = emod;
        }

        this.setState({ rowDataList: data });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateDisplayTab1 = async () => {
    let { tabName } = this.props;
    let requestBody = {
      tab: tabName,
      first_time_loading: "true",
    };
    let _engagedCommentHistory = {};
    let rowDataList = [];
    let last_key = "";
    let indx = 0;
    requestBody = { ...requestBody, lastEvaluatedKey: "" };

    do {
      requestBody = {
        ...requestBody,
        lastEvaluatedKey: last_key,
      };

      if (indx === 1) delete requestBody["first_time_loading"];

      await getDashboardTableData(requestBody)
        .then((res) => {
          let userDataList = [];
          let batchRowDataList = [];
          let tempObj;
          let rowData;
          let domain_messageid_files = {};

          let dataFromApi = res.data.data;
          last_key = res.data.old_key || undefined;
          for (let key in dataFromApi) {
            tempObj = {};
            for (let dataKey in dataFromApi[key]) {
              tempObj[dataKey] = dataFromApi[key][dataKey];
            }
            userDataList.push(tempObj);
          }

          for (let userData of userDataList) {
            let quote_status;
            let step;
            let excluded_status;

            // patch code to not display entries with the following campaign names
            let incorrectCampaignNames = new Set([
              "AEMheybtb",
              "TfgDhbgf",
              "BAybtb",
              "AEybtb",
            ]);
            if (incorrectCampaignNames.has(userData?.latestCampaign)) continue;

            let firstFilter = userData.onlyPaygo === "true" ? "PAYGO" : "PEO";
            let secondFilter =
              userData.client_source === "Hotschedules"
                ? "Hotschedules"
                : "Affinity";

            let email_specific_status;
            let landing_email_status, landing_email_get_quote_clicked;

            for (let email in userData.email_specific) {
              let db_quote_status = userData.email_specific[email].quote_status;
              let db_excluded =
                userData.email_specific[email].nonEligibilityList;
              if (db_excluded && db_excluded.length > 0) {
                excluded_status = true;
              }

              if (
                userData.email_specific[email].companyProfile &&
                userData.email_specific[email].companyProfile.status &&
                userData.email_specific[email].companyProfile.status.value
              ) {
                let emailStatus =
                  userData.email_specific[email].companyProfile.status.value;
                if (!email_specific_status) email_specific_status = emailStatus;
                else if (
                  statusPriorityMap[emailStatus] <
                  statusPriorityMap[email_specific_status]
                ) {
                  email_specific_status = emailStatus;
                }
              }

              if (!landing_email_status) {
                landing_email_status = userData.email_specific[email]
                  .landingPage
                  ? userData.email_specific[email].landingPage
                  : null;
              }

              if (!landing_email_get_quote_clicked) {
                landing_email_get_quote_clicked = userData.email_specific[email]
                  .homeGetQuote
                  ? userData.email_specific[email].homeGetQuote
                  : null;
              }

              if (
                (!quote_status && db_quote_status) ||
                (quote_status === "reject" && db_quote_status) ||
                db_quote_status === "proceed_uw"
              ) {
                quote_status = db_quote_status;
              }
            }

            if (userData.isSalespersonSubmitted) {
              step = "Submitted";
            } else if (userData.isUserSubmitted) {
              step = "User Submitted";
            } else if (userData.isSubmitted) {
              step = "Submitted";
            } else if (Number(userData.proposalGeneratedCount) > 0) {
              step = "Proposal Generated";
            } else if (quote_status) {
              if (quote_status === "uwportal") {
                step = "UW Portal";
              } else if (quote_status === "proceed_uw") {
                step = "Approved";
              } else if (quote_status === "reject") {
                step = "Rejected";
              }
            } else if (Number(userData.quoteGeneratedCount) > 0) {
              step = "Quote Generated";
            } else if (userData.formStage) {
              if (userData.formStage === "one") {
                step = "Company Profile";
                if (landing_email_get_quote_clicked) {
                  step += " (Get Quote)";
                }
              } else {
                if (excluded_status) {
                  step = "Not Eligible";
                } else {
                  step = "UW Questions";
                }
              }
            } else if (userData.visitCount && Number(userData.visitCount) > 0) {
              step = "Landing Page";
            } else if (landing_email_status) {
              step =
                landing_email_status === "affinityLogo"
                  ? "Affinity Logo Clicked"
                  : "Fourth Logo Clicked";
            } else {
              step = "-";
            }
            if (email_specific_status) {
              step =
                statusPriorityMap[email_specific_status] <
                statusPriorityMap[step]
                  ? email_specific_status
                  : step;
            }

            let currProspectDetails = {
              companyProfile: userData.companyProfile,
              childrenLoc: userData.payrollData,
              emodStatesData: userData.emodStatesData,
            };

            if (userData?.brokerDetails) {
              currProspectDetails.brokerDetails = userData.brokerDetails;
            }

            rowData = {
              visitDate:
                userData?.timestamp &&
                moment(Number(userData?.timestamp))
                  .utcOffset("-0500")
                  .format("M/D/YYYY - hh:mm A"),
              userEmail: userData.email,
              quoteGeneratedCount: userData.quoteGeneratedCount || 0,
              proposalGeneratedCount: userData.proposalGeneratedCount || 0,
              uuid: userData.uuid,
              phoneNumber: userData.phoneNumber,
              payroll: userData.payroll,
              indicationCost: userData.indicationCost,
              emod:
                userData.emod && Number(userData.emod)
                  ? parseFloat(userData.emod).toFixed(2)
                  : "-",
              uwEmail: userData.uwEmail,
              effectiveDate: userData?.effectiveDate
                ? moment(userData?.effectiveDate).format("x")
                : "",
              contactName: userData?.contactName || "",
              companyName: userData?.companyName || "",
              comment: userData?.comment
                ? typeof userData?.comment === "string"
                  ? [
                      {
                        ...initialcomment,
                        comment: userData?.comment,
                        commentTimestamp:
                          userData?.timestamp || userData?.uploadedTimeStamp,
                      },
                    ]
                  : userData?.comment
                : [{ ...initialcomment }],
              domain: userData?.domain,
              visitCount: userData?.visitCount || 0,
              step,
              latestCampaign: userData?.latestCampaign,
              email_specific: userData?.email_specific,
              messageID: userData?.messageID,
              dashboardFiles: userData?.dashboardFiles,
              preUwFiles: userData?.preUwFiles,
              fileListToStore: userData?.fileListToStore,
              zohoDate: userData?.zywave_uploaded_date
                ? moment(userData?.zywave_uploaded_date, "YYYY-MM-DD").format(
                    "M/D/YYYY"
                  )
                : "-",
              currProspectDetails,
              salesID: userData?.domain,
              formStage: userData?.formStage,
              address: userData?.address,
              uploadedDate: userData?.uploadedDate,
              quoteData: userData?.quoteData,
              state: userData?.state || "-",
              city: userData?.city || "-",
              total_emp: userData?.total_emp || 0,
              isPreUnderwritten: userData?.preUwStatus ? true : false,
              salespersonName:
                userData?.salespersonNameAttr || userData?.salespersonName,
              uploadedTimeStamp: userData?.uploadedTimeStamp
                ? moment(userData?.uploadedTimeStamp, "x").format("M/D/YYYY")
                : userData?.zywave_uploaded_date
                ? moment(userData?.zywave_uploaded_date, "YYYY-MM-DD").format(
                    "M/D/YYYY"
                  )
                : "-",
              source: userData.source,
              salescomment: userData?.salescomment
                ? typeof userData?.salescomment === "string"
                  ? [
                      {
                        ...initialcomment,
                        comment: userData?.salescomment,
                        commentTimestamp:
                          userData?.timestamp || userData?.uploadedTimeStamp,
                      },
                    ]
                  : userData?.salescomment
                : [{ ...initialcomment }],
              admincomment: userData?.admincomment
                ? typeof userData?.admincomment === "string"
                  ? [
                      {
                        ...initialcomment,
                        comment: userData?.admincomment,
                        commentTimestamp:
                          userData?.timestamp || userData?.uploadedTimeStamp,
                      },
                    ]
                  : userData?.admincomment
                : [{ ...initialcomment }],
              tableName: userData.tableName,
              smcomment: userData?.smcomment
                ? typeof userData?.smcomment === "string"
                  ? [
                      {
                        ...initialcomment,
                        comment: userData?.smcomment,
                        commentTimestamp:
                          userData?.timestamp || userData?.uploadedTimeStamp,
                      },
                    ]
                  : userData?.smcomment
                : [{ ...initialcomment }],
              dealFlag:
                userData?.dealData?.status === "true"
                  ? "closed"
                  : userData?.dealData?.status === "false"
                  ? "lost"
                  : "",
              dealFlagFilter:
                userData?.dealData?.status === "true"
                  ? "won"
                  : userData?.dealData?.status === "false"
                  ? "lost"
                  : "",
              dealConfirmed: userData?.dealConfirmed,
              recordDetails: {
                domain: userData.domain,
              },
              tab: this.props.tabName,
              sortKeyList: userData?.sortKeyList,
            };

            createCommentForDownload(rowData, "comment");
            createCommentForDownload(rowData, "admincomment");
            createCommentForDownload(rowData, "salescomment");
            createCommentForDownload(rowData, "smcomment");

            //console.log("deal confirmed", rowData.dealConfirmed);
            if (rowData?.email_specific) {
              Object.values(rowData.email_specific).map((email) => {
                if (
                  email.companyProfile &&
                  email.companyProfile.contact_number &&
                  email.companyProfile.contact_number.value
                ) {
                  email.companyProfile.contact_number.value = this.appendRemaining(
                    email.companyProfile.contact_number.value.toString()
                  );
                }
              });
            } else {
              if (rowData.phoneNumber)
                rowData.phoneNumber = this.appendRemaining(
                  rowData.phoneNumber.toString()
                );
            }
            if (rowData.domain) {
              _engagedCommentHistory[rowData.domain] = rowData?.admincomment
                ? typeof rowData.admincomment === "string"
                  ? [
                      {
                        ...initialcomment,
                        comment: rowData.admincomment,
                        commentTimestamp: rowData?.visitDate,
                      },
                    ]
                  : rowData.admincomment
                : [{ ...initialcomment }];
            }

            // rowDataList[firstFilter][secondFilter].push(rowData);
            rowDataList.push(rowData);
            batchRowDataList.push(rowData);

            domain_messageid_files[rowData.domain] = {};
            if (rowData.messageID)
              domain_messageid_files[rowData.domain]["messageID"] =
                rowData.messageID;
            if (rowData.dashboardFiles)
              domain_messageid_files[rowData.domain]["dashboardFiles"] =
                rowData.dashboardFiles;
            if (rowData.preUwFiles) {
              let _preUwFiles = [];
              rowData.preUwFiles.forEach((ele) => {
                _preUwFiles.push(ele.key);
              });
              domain_messageid_files[rowData.domain][
                "preUwFiles"
              ] = _preUwFiles;
            }

            if (rowData.fileListToStore) {
              let _fileListToStore = [];
              rowData.fileListToStore.forEach((ele) => {
                _fileListToStore.push(ele.key);
              });
              domain_messageid_files[rowData.domain][
                "fileListToStore"
              ] = _fileListToStore;
            }
          }

          this.setState((prevState) => {
            let engagedCommentHistory = JSON.parse(
              JSON.stringify(prevState.engagedCommentHistory)
            );

            return {
              engagedCommentHistory: {
                ..._engagedCommentHistory,
                ...engagedCommentHistory,
              },
              batchCount: this.state.batchCount + 1,
              domain_messageid_files,
            };
          });

          this.props.setRowDataList(
            "tab1",
            rowDataList,
            false,
            this.state.batchCount,
            batchRowDataList
          );
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
      indx += 1;
    } while (indx === 1 || last_key);
    if (!last_key) {
      this.setState({ dataLoading: false });
    }
  };

  updateDisplayTab2 = async () => {
    let requestBody = {
      tab: "tab2",
      firstTimeLoading: "true",
    };
    let _campaignedCommentsHistory = {};
    let domain_messageid_files = {};

    let rowDataList = [];
    let tempObj1;
    let lastKey1;
    let lastKey2;
    let indx = 0;
    requestBody = {
      ...requestBody,
      lastEvaluatedKey1: "",
      lastEvaluatedKey2: "",
    };

    do {
      if (lastKey1) {
        requestBody["lastEvaluatedKey1"] = lastKey1;
      } else {
        requestBody["lastEvaluatedKey1"] = undefined;
      }
      if (lastKey2) {
        requestBody["lastEvaluatedKey2"] = lastKey2;
      } else {
        requestBody["lastEvaluatedKey2"] = undefined;
      }

      if (indx === 1) delete requestBody["firstTimeLoading"];

      await getDashboardTableData(requestBody)
        .then((res) => {
          let userDataList = [];
          lastKey1 = res.data.oldKey1 || undefined;
          lastKey2 = res.data.oldKey2 || undefined;

          let tempObj;
          let rowData;
          let batchRowDataList = [];
          // let rowDataList = {
          //   PEO: {
          //     Affinity: [],
          //     Hotschedules: [],
          //   },
          //   PAYGO: {
          //     Affinity: [],
          //     Hotschedules: [],
          //   },
          // };

          // let engagedDataOb = {
          //   "All": [],
          //   "HotSchedules": [],
          //   "Affinity": []
          // };

          let dataFromApi = res.data.data;
          for (let key in dataFromApi) {
            tempObj = {};
            for (let dataKey in dataFromApi[key]) {
              tempObj[dataKey] = dataFromApi[key][dataKey];
            }
            userDataList.push(tempObj);
          }

          for (let userData of userDataList) {
            let quote_status;
            let step;
            let excluded_status;

            // patch code to not display entries with the following campaign names
            let incorrectCampaignNames = new Set([
              "AEMheybtb",
              "TfgDhbgf",
              "BAybtb",
              "AEybtb",
            ]);
            if (incorrectCampaignNames.has(userData?.latestCampaign)) continue;

            let firstFilter = userData.onlyPaygo === "true" ? "PAYGO" : "PEO";
            let secondFilter =
              userData.client_source === "Hotschedules"
                ? "Hotschedules"
                : "Affinity";

            let email_specific_status;
            let landing_email_status, landing_email_get_quote_clicked;

            for (let email in userData.email_specific) {
              let db_quote_status = userData.email_specific[email].quote_status;
              let db_excluded =
                userData.email_specific[email].nonEligibilityList;
              if (db_excluded && db_excluded.length > 0) {
                excluded_status = true;
              }

              if (
                userData.email_specific[email].companyProfile &&
                userData.email_specific[email].companyProfile.status &&
                userData.email_specific[email].companyProfile.status.value
              ) {
                let emailStatus =
                  userData.email_specific[email].companyProfile.status.value;
                if (!email_specific_status) email_specific_status = emailStatus;
                else if (
                  statusPriorityMap[emailStatus] <
                  statusPriorityMap[email_specific_status]
                ) {
                  email_specific_status = emailStatus;
                }
              }
              if (!landing_email_status) {
                landing_email_status = userData.email_specific[email]
                  .landingPage
                  ? userData.email_specific[email].landingPage
                  : null;
              }

              if (!landing_email_get_quote_clicked) {
                landing_email_get_quote_clicked = userData.email_specific[email]
                  .homeGetQuote
                  ? userData.email_specific[email].homeGetQuote
                  : null;
              }

              if (
                (!quote_status && db_quote_status) ||
                (quote_status === "reject" && db_quote_status) ||
                db_quote_status === "proceed_uw"
              ) {
                quote_status = db_quote_status;
              }
            }

            if (userData.isSalespersonSubmitted) {
              step = "Submitted";
            } else if (userData.isUserSubmitted) {
              step = "User Submitted";
            } else if (userData.isSubmitted) {
              step = "Submitted";
            } else if (Number(userData.proposalGeneratedCount) > 0) {
              step = "Proposal Generated";
            } else if (quote_status) {
              if (quote_status === "uwportal") {
                step = "UW Portal";
              } else if (quote_status === "proceed_uw") {
                step = "Approved";
              } else if (quote_status === "reject") {
                step = "Rejected";
              }
            } else if (Number(userData.quoteGeneratedCount) > 0) {
              step = "Quote Generated";
            } else if (userData.formStage) {
              if (userData.formStage === "one") {
                step = "Company Profile";
                if (landing_email_get_quote_clicked) {
                  step += " (Get Quote)";
                }
              } else {
                if (excluded_status) {
                  step = "Not Eligible";
                } else {
                  step = "UW Questions";
                }
              }
            } else if (userData.visitCount && Number(userData.visitCount) > 0) {
              step = "Landing Page";
            } else if (landing_email_status) {
              step =
                landing_email_status === "affinityLogo"
                  ? "Affinity Logo Clicked"
                  : "Fourth Logo Clicked";
            } else {
              step = "-";
            }
            if (email_specific_status) {
              step =
                statusPriorityMap[email_specific_status] <
                statusPriorityMap[step]
                  ? email_specific_status
                  : step;
            }

            let currProspectDetails = {
              companyProfile: userData.companyProfile,
              childrenLoc: userData.payrollData,
              emodStatesData: userData.emodStatesData,
              // companyProfileAddQuestions: userData.companyProfileAddQuestions,
              // uwQues: userData.uwQues,
              // requestedPricing: userData.requestedPricing
            };

            if (userData?.brokerDetails) {
              currProspectDetails.brokerDetails = userData.brokerDetails;
            }
            // let total_emp = 0;
            // if (userData.payrollData) {
            //   let childrenLoc = userData.payrollData;
            //   for (let locIndex in childrenLoc) {
            //     let loc = childrenLoc[locIndex];
            //     for (let ccIndex in loc.classCodesInfo) {
            //       let cc = loc.classCodesInfo[ccIndex];
            //       total_emp =
            //         total_emp +
            //         Number(cc?.ft?.value || 0) +
            //         Number(cc?.pt?.value || 0);
            //     }
            //   }
            // }
            rowData = {
              visitDate:
                userData.timestamp &&
                moment(Number(userData.timestamp))
                  .utcOffset("-0500")
                  .format("M/D/YYYY - hh:mm A"),
              userEmail: userData.email,
              quoteGeneratedCount: userData.quoteGeneratedCount || 0,
              proposalGeneratedCount: userData.proposalGeneratedCount || 0,
              uuid: userData.uuid,
              phoneNumber: userData.phoneNumber,
              payroll: userData.payroll,
              indicationCost: userData.indicationCost,
              emod:
                userData.emod && Number(userData.emod)
                  ? parseFloat(userData.emod).toFixed(2)
                  : "-",
              uwEmail: userData.uwEmail,
              effectiveDate: userData.effectiveDate
                ? moment(userData.effectiveDate).format("x")
                : "",
              contactName: userData.contactName || "",
              companyName: userData.companyName || "",
              comment: userData?.comment
                ? typeof userData?.comment === "string"
                  ? [
                      {
                        ...initialcomment,
                        comment: userData?.comment,
                        commentTimestamp:
                          userData?.timestamp || userData?.uploadedTimeStamp,
                      },
                    ]
                  : userData?.comment
                : [{ ...initialcomment }],
              domain: userData.domain,
              visitCount: userData.visitCount || 0,
              step,
              latestCampaign: userData.latestCampaign,
              email_specific: userData.email_specific,
              messageID: userData.messageID,
              dashboardFiles: userData.dashboardFiles,
              zohoDate: userData.zywave_uploaded_date
                ? moment(userData.zywave_uploaded_date, "YYYY-MM-DD").format(
                    "M/D/YYYY"
                  )
                : "-",
              currProspectDetails,
              salesID: userData.domain,
              formStage: userData.formStage,
              address: userData.address,
              quoteData: userData.quoteData,
              uploadedDate: userData.uploadedDate,
              state: userData?.state || "-",
              city: userData?.city || "-",
              total_emp: userData?.total_emp || 0,
              salespersonName:
                userData.salespersonNameAttr || userData.salespersonName,
              uploadedTimeStamp: userData.uploadedTimeStamp
                ? moment(userData.uploadedTimeStamp, "x").format("M/D/YYYY")
                : userData.zywave_uploaded_date
                ? moment(userData.zywave_uploaded_date, "YYYY-MM-DD").format(
                    "M/D/YYYY"
                  )
                : "-",
              source: userData.source,
              isPreUnderwritten: userData.preUwStatus ? true : false,
              salescomment: userData?.salescomment
                ? typeof userData?.salescomment === "string"
                  ? [
                      {
                        ...initialcomment,
                        comment: userData?.salescomment,
                        commentTimestamp:
                          userData?.timestamp || userData?.uploadedTimeStamp,
                      },
                    ]
                  : userData?.salescomment
                : [{ ...initialcomment }],
              admincomment: userData?.admincomment
                ? typeof userData?.admincomment === "string"
                  ? [
                      {
                        ...initialcomment,
                        comment: userData?.admincomment,
                        commentTimestamp:
                          userData?.timestamp || userData?.uploadedTimeStamp,
                      },
                    ]
                  : userData?.admincomment
                : [{ ...initialcomment }],
              tableName: userData.tableName,
              smcomment: userData?.smcomment
                ? typeof userData?.smcomment === "string"
                  ? [
                      {
                        ...initialcomment,
                        comment: userData?.smcomment,
                        commentTimestamp:
                          userData?.timestamp || userData?.uploadedTimeStamp,
                      },
                    ]
                  : userData?.smcomment
                : [{ ...initialcomment }],
              dealFlag:
                userData?.dealData?.status === "true"
                  ? "closed"
                  : userData?.dealData?.status === "false"
                  ? "lost"
                  : "",
              dealFlagFilter:
                userData?.dealData?.status === "true"
                  ? "won"
                  : userData?.dealData?.status === "false"
                  ? "lost"
                  : "",
              dealConfirmed: userData?.dealConfirmed,
              recordDetails: {
                domain: userData.domain,
                isMailCmpn:
                  userData.tableName === "campaignTable" ? true : false,
              },
              tab: this.props.tabName,
              sortKeyList: userData?.sortKeyList,
            };

            createCommentForDownload(rowData, "comment");
            createCommentForDownload(rowData, "admincomment");
            createCommentForDownload(rowData, "salescomment");
            createCommentForDownload(rowData, "smcomment");

            if (rowData.email_specific) {
              Object.values(rowData.email_specific).map((email) => {
                if (
                  email.companyProfile &&
                  email.companyProfile.contact_number &&
                  email.companyProfile.contact_number.value
                ) {
                  email.companyProfile.contact_number.value = this.appendRemaining(
                    email.companyProfile.contact_number.value.toString()
                  );
                }
              });
            } else {
              if (rowData.phoneNumber)
                rowData.phoneNumber = this.appendRemaining(
                  rowData.phoneNumber.toString()
                );
            }

            if (rowData.domain) {
              _campaignedCommentsHistory[rowData.domain] = rowData?.admincomment
                ? typeof rowData.admincomment === "string"
                  ? [
                      {
                        ...initialcomment,
                        comment: rowData.admincomment,
                        commentTimestamp: rowData?.visitDate,
                      },
                    ]
                  : rowData.admincomment
                : [{ ...initialcomment }];
            }

            // rowDataList[firstFilter][secondFilter].push(rowData);
            rowDataList.push(rowData);
            batchRowDataList.push(rowData);

            // engagedDataOb.All.push(rowData);

            // if (rowData.source === "affinity") {
            //   engagedDataOb.Affinity.push(rowData);
            // } else if (rowData.source === "hotschedules") {
            //   engagedDataOb.HotSchedules.push(rowData);
            // }

            domain_messageid_files[rowData.domain] = {};
            if (rowData.messageID)
              domain_messageid_files[rowData.domain]["messageID"] =
                rowData.messageID;
            if (rowData.dashboardFiles)
              domain_messageid_files[rowData.domain]["dashboardFiles"] =
                rowData.dashboardFiles;
            if (userData.preUwFiles) {
              let _preUwFiles = [];
              userData.preUwFiles.forEach((ele) => {
                _preUwFiles.push(ele.key);
              });
              domain_messageid_files[userData.domain][
                "preUwFiles"
              ] = _preUwFiles;
            }

            if (userData.fileListToStore) {
              let _fileListToStore = [];
              userData.fileListToStore.forEach((ele) => {
                _fileListToStore.push(ele.key);
              });
              domain_messageid_files[userData.domain][
                "fileListToStore"
              ] = _fileListToStore;
            }
          }

          this.setState((prevState) => {
            let campaignedCommentsHistory = JSON.parse(
              JSON.stringify(prevState.campaignedCommentsHistory)
            );

            return {
              campaignedCommentsHistory: {
                ..._campaignedCommentsHistory,
                ...campaignedCommentsHistory,
              },
              batchCount: this.state.batchCount + 1,
              domain_messageid_files,
            };
          });

          this.props.setRowDataList(
            "tab2",
            rowDataList,
            false,
            this.state.batchCount,
            batchRowDataList
          );
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
      indx += 1;
    } while (lastKey1 || lastKey2);
    if (!lastKey1 || !lastKey2) {
      this.setState({ dataLoading: false });
    }
  };

  render() {
    let { selected, tabName } = this.props;
    let {
      showModel,
      modalTitle,
      modalContent,
      sourceSelected,
      popupData,
      openModal,
      modalBody,
    } = this.state;
    // console.log("sourceSelected: ", sourceSelected, " tabName: ", tabName);
    return (
      <>
        {selected === tabName ? (
          <div
            className={"admin-dashboard " + `${tabName}`}
            style={this.state.style}
          >
            {tabName === "tab1" && (
              <div className="mt-3 mb-3 dashboard-dropdown-row">
                <select
                  value={sourceSelected}
                  onChange={(e) => {
                    this.setState({ sourceSelected: e.target.value });
                  }}
                >
                  <option value={"All"}>All</option>
                  <option value={"HotSchedules"}>Hot Schedules</option>
                  <option value={"Affinity"}>Affinity</option>
                </select>
              </div>
            )}
            <div ref={this.myRef}>{this.setTableData()}</div>
            <Modal
              show={showModel}
              onHide={() => {
                this.setState({
                  showModel: false,
                  modalTitle: null,
                  modalContent: null,
                });
              }}
              dialogClassName="model-download modal-dialog-centered"
            >
              <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{modalContent}</Modal.Body>
            </Modal>
          </div>
        ) : (
          ""
        )}
        <Dialog open={openModal} onClose={this.handleCloseModal}>
          <DialogTitle>
            <p style={{ textAlign: "center" }}>
              {(modalBody.length > 0 &&
                modalBody?.[0] !== "" &&
                modalBody?.[0]?.comment !== "") ||
              modalBody?.[0]?.previousComment
                ? "Comment History"
                : "No Comment History"}
            </p>
            <IconButton
              aria-label="close"
              onClick={this.handleCloseModal}
              style={{ position: "absolute", right: "5px", top: "8px" }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {modalBody.length > 0 &&
              modalBody[0] !== "" &&
              modalBody.map((item, index) => {
                console.log(item.commentTimestamp);
                if (typeof item.commentTimestamp === "string") {
                  item.commentTimestamp = +item.commentTimestamp;
                }
                let date = moment(item.commentTimestamp)
                  .format("M/D/YY h:mm A")
                  .split(" ")[0];
                let time = moment(item.commentTimestamp)
                  .format("M/D/YY h:mm A")
                  .split(" ")[1];

                if (item.comment !== "") {
                  if (item.commentTimestamp || item.email || item) {
                    return (
                      <div>
                        <div>
                          <span>
                            <b>{date}</b>
                          </span>
                          <span
                            style={{
                              marginLeft: "10px",
                              marginRight: "10px",
                            }}
                          >
                            <b>{time}</b>
                          </span>
                          <span>{item.email ? "- " : ""}</span>
                          <span>
                            <b>{item.email?.split("@")[0]}</b>
                          </span>
                        </div>
                        <div>
                          <p>
                            {item?.comment
                              ? item.comment
                              : modalBody[index + 1]?.comment
                              ? modalBody[index + 1].comment
                              : item}
                          </p>
                        </div>
                        <br />
                      </div>
                    );
                  }
                } else {
                  return;
                }
              })}
          </DialogContent>
        </Dialog>
        <Toaster />
        {popupData.show && (
          <Popup popupData={popupData} updatePopUpData={this.updatePopUpData} />
        )}
      </>
    );
  }
}

class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      loggedInEmail: "",
    };
  }
  onChangeHandler(target) {
    this.setState({ value: target });
  }

  componentDidMount() {
    this.loggedInEmailId();
  }

  loggedInEmailId = async () => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      this.setState({ loggedInEmail: sessionData["attributes"]["email"] });
    } catch (error) {
      console.error("Error fetching authenticated user:", error);
    }
  };

  render() {
    let { value, loggedInEmail } = this.state;
    const checkcommentAvailble = (comment) => {
      if (comment?.[0]?.comment === "") {
        if (comment?.length > 1) {
          return "#FFE7C1";
        }
      } else if (comment?.length > 0) {
        return "#FFE7C1";
      }
    };

    return (
      <textarea
        value={value}
        className="underwriter_comment"
        rows="1"
        style={{
          width: "100%",
          borderRadius: "4px",
          backgroundColor: checkcommentAvailble(this.props.comment),
        }}
        onChange={(e) => this.onChangeHandler(e.target.value)}
        onBlur={(e) => {
          console.log(this.props.comment);
          debugger;
          let adminCommentsCopy = JSON.parse(
            JSON.stringify(this.props.comment)
          );
          let currentTimestamp = moment().valueOf();
          let dataToSave = {};

          if (e.target.value.trim() !== "") {
            if (e.target.value.trim() !== adminCommentsCopy[0].comment) {
              e.target.value.trim() !== adminCommentsCopy[0]?.comment &&
                adminCommentsCopy.unshift({
                  comment: e.target.value.trim(),
                  commentTimestamp: currentTimestamp,
                  email: loggedInEmail,
                });
            } else {
              return; // Return because no comment was entered;
            }
          } else if (
            e.target.value.length === 0 ||
            e.target.value.trim() === ""
          ) {
            if (e.target.value.trim() !== adminCommentsCopy[0]?.comment) {
              adminCommentsCopy.unshift({
                comment: e.target.value.trim(),
                commentTimestamp: currentTimestamp,
                previousComment: "true",
                email: loggedInEmail,
              });
            } else {
              return; // Return because no comment was entered;
            }
          }

          if (this.props.rowDataSortKeyList) {
            dataToSave.sortKeyList = this.props.rowDataSortKeyList;
          }
          this.props.UpdateCommentsHistoryState(
            this.props.rowDataID,
            adminCommentsCopy,
            this.props.rowData
          );
          dataToSave[this.props.rowDataName] = this.props.rowDataID;
          dataToSave[this.props.rowDataComments] = adminCommentsCopy;
          dataToSave[this.props.rowDataComments] &&
            this.props.callback(dataToSave, this.props.rowData);
        }}
      ></textarea>
    );
  }
}
export default DashboardAdmin;
